.gifting-banner {
    position: relative;
    padding-top: 55.56%;
    width: 100%;
}

.giftingList-page {
    background: rgba(245, 245, 245, 0.8);
}

.giftingList-page .gift-main-banner {
    background-color: #FCFCFC;
}

.giftingList-page .gift-title {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #1D1F22;
}

.giftingList-page .gift-frameName {
    font-style: normal;
    font-weight: 390;
    font-size: 14px;
    line-height: 18px;
    color: #43464E;
    margin-bottom: 4px;
}

.gift-heading .main-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 120%;
}

.gift-heading p {
    max-width: 406px;
    text-align: center;
    font-style: normal;
    font-weight: 390;
    font-size: 16px;
    line-height: 120%;
    color: #404040;
    margin-top: 16px;
}

.gift-heading .grey-button {
    font-style: normal;
    font-weight: 420;
    font-size: 18px;
    line-height: 26px;
    width: 191px;
    height: 42px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 191px;
}

.gift-list-container {
    background: rgba(245, 245, 245, 0.8);
    padding: 80px 15%;
}

.gift-list-container .frame-ind {
    margin-left: 3.5%;
}

.gift-list-container .frame-list {
    column-gap: 4%;
}

.gifting-block {
    flex: 0 0 30%;
    height: min-content;
    padding: 16px;
    padding-bottom: 48px;
    background-color: white;
    border: 1px solid #F1F2F3;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.gifting-imageContainer {
    position: relative;
    padding-top: 150.186%;
    width: 100%;
    margin-bottom: 20px;
}

.gifting-block .gifting-imageContainer .price-box {
    position: absolute;
    bottom: 10px;
    right: 10px;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 4px 8px;
    z-index: 2;
    font-weight: 450;
    font-size: 14px;
    line-height: 18px;
    color: #131718;
}

.gifting-banner img,
.full-banner-container img,
.gifting-imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.full-banner-container {
    position: relative;
    padding-top: 60.98%;
    width: 100%;
}

.gifting-button {
    cursor: not-allowed;
    margin-right: 12px;
    margin-bottom: 10%;
    color: #404040;
    padding: 12px 24px;
    border-radius: 8px;
    background-color: #EAEAEA;
    font-style: normal;
    font-weight: 390;
    font-size: 16px;
    line-height: 14px;
}

.gifting-button.active {
    cursor: pointer;
    background-color: #404040;
    color: white;
}

.giftingDetails-page .imageContainer {
    position: relative;
    padding-top: 97.18%;
}

.giftingDetails-page .imageContainer .imageBox {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding-bottom: 12%;
}

.giftingDetails-page .imageContainer .imageBox .gifting-img {
    display: flex;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.gift-zoom-img {
    height: 85vh;
    max-height: 100vh;
}

.upload_div {
    align-items: center;
    cursor: pointer;
    margin-top: 28px;
    color: #404040;
}

.upload_div .upload_text {
    font-style: normal;
    font-weight: 390;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    text-transform: capitalize;
    color: #404040;
}

.uploaded_div {
    margin-top: 22px;
    align-items: center;
}

.uploaded_div .uploaded_sign {
    width: 20px;
    height: 20px;
    margin-left: 14px;
    margin-right: 26px;
}

.uploaded_div .change_text {
    font-style: normal;
    font-weight: 390;
    font-size: 12px;
    line-height: 24px;

    /* identical to box height, or 200% */
    text-decoration-line: underline;

    color: #404040;
}

.uploaded_div .name_text {
    font-style: normal;
    font-weight: 390;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    color: #404040;
}

.upload_image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 16px;
    margin-right: 12px;
}

.giftingDetails-page .zoom-button-container {
    display: flex;
    bottom: 15%;
    right: 25%;
}


.giftingDetails-page .gift-details {
    margin-top: 5%;
    margin-bottom: 10%;
}

@media (min-width:575px) {
    .giftingDetails-page .add-cart-button {
        width: 170px;
        min-width: 170px;
        height: 52px;
        font-size: 18px !important;
        line-height: 120%;
    }

    .giftingDetails-page .desc-review-box {
        margin-top: 16%;
    }

}

@media (max-width: 767px) {
    .gift-list-container {
        padding: 12% 5%;
        margin: 0 5%;
    }

    .giftingDetails-page .main-heading {
        font-size: 20px;
        line-height: 120%;
    }

    .gift-zoom-img {
        width: 90%;
        height: auto;
    }

    .giftingDetails-page .gifting-img {
        height: 350px;
    }

    .giftingDetails-page .zoom-button-container {
        bottom: 5%;
    }

    .giftingDetails-page .desc-review-box {
        margin-top: 62px;
    }

    .gifting-block {
        flex: 0 0 48%;
        padding: 9px;
    }

    .gift-heading .main-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
    }

    .gift-heading p {
        font-weight: 390;
        font-size: 12px;
        line-height: 120%;
        margin-top: 8px;
    }

    .gifting-block .gifting-imageContainer .price-box {
        font-style: normal;
        font-weight: 450;
        font-size: 8px;
        line-height: 120%;
        /* identical to box height, or 10px */
        text-align: center;
        color: #131718;
    }

    .giftingList-page .gift-title {
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .giftingList-page .gift-frameName {
        font-weight: 390;
        font-size: 8px;
        line-height: 12px;
        margin-bottom: 0;
    }

    .gift-heading {
        padding: 78px 0;
    }

    .gifting-block:nth-child(2n+1) {
        margin-left: 0;
    }
}

@media screen and (max-width:992px) and (min-width: 575px) {
    .gift-list-container {
        padding-left: 2%;
        padding-right: 2%;
    }
}