.tabs-mobile .accordion__button {
    background-color: #fff;
    padding: 18px 0;
}

.tabs-mobile .accordion {
    border: none;
}

.tabs-mobile .accordion__button[aria-expanded='true'],
.tabs-mobile .accordion__button[aria-selected='true'] {
    color: #6C3F2C;
}

.tabs-mobile .accordion__panel {
    padding: 20px 0;
}

.read-all {
    font-size: 12px;
    line-height: 160%;
    text-align: right;
    color: #1D1F22;
}

.star-div {
    display: flex;
    align-items: center;
}

.row {
    display: flex;
    flex-direction: row;
}

.column {
    display: flex;
    flex-direction: column;
}

.review-menu {
    border: white solid 1px;
    z-index: 10;
    overflow-x: hidden;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pagination {
    display: inline-block;
}

.pagination a {
    color: black;
    float: left;
    padding: 5px 5px;
    text-decoration: none;
}

.nav {
    text-decoration: dashed;
}

.star-rating {
    display: flex;
    flex: 1;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 30px;

}

.starring {
    display: flex;
    align-items: center;
    justify-content: center;
}

.nowrap {
    flex-wrap: nowrap;
}

.single-rating {
    margin-top: 40px;
}

.star-date {
    margin-left: 10px;
    margin-top: 4px;
}

.rating-head {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: capitalize;
}

.rating-head .row {
    align-items: center;
}

.rating-text {
    white-space: 'break-spaces';
    flex-wrap: 'wrap';
}

.star {
    display: flex;
    align-items: center;
    justify-content: center;
}

.h4 {
    margin-top: 20px;
    font-weight: bold;
}

.verified {
    margin-right: 5px;
    object-fit: contain;
    height: 20px;
}

.tabs {
    display: flex;
    flex-direction: row;
    border-bottom: 2px solid #F1F2F3;

}

.tab-select {
    background-color: white;
    -webkit-appearance: none;
    padding: 0;
    margin-right: 10px;
    border: none;
    text-align: baseline;
    cursor: pointer;
}

.tab-select:focus {
    border: none;
    outline: 0;
    opacity: 1;
}


@media screen and (max-width: 575px) {
    .tabs-mobile h4 {
        font-weight: normal;
        font-size: 18px;
    }
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.load-file {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #00000066;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}