.stay-page-component {
    padding: 121px 0;
}

.stay-container {
    max-width: 460px;
}

.stay-heading {
    font-size: 32px;
    line-height: 42px;
    color: #1e2022;
}

.stay-description {
    font-size: 17px;
    line-height: 27px;
    color: #77838f;
}

.stay-input {
    margin-top: 40px;
    background: rgba(233, 233, 233, 0.1);
    display: flex;
    align-items: center;
    height: 52px;
    padding: 2px 6px;
    border-radius: 4px;
}

.stay-input .stay-inputTag {
    background: transparent;
    border: none;
    width: 405px;
}

.mail-btn {
    background-color: #404040;
    height: 40px;
    width: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mail-btn img {
    width: 20px;
    height: 23px;
}