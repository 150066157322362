@font-face {
  font-family: 'CircularStd';
  src: local('MyFont'), url(./assets/fonts/CircularStd-Medium.otf) format('opentype');
}
@font-face {
  font-family: 'bradLight';
  src: local('MyFont'), url(./assets/fonts/Brandon_light.otf) format('opentype');
}

.bradLight{
  font-family: 'bradLight';
}

.font-circular-bold{
  font-family: 'CircularStd';
}
.cart-item-title-grey{
  font-family: 'CircularStd';
  font-weight: 700;

}
.cursor{
  cursor: pointer;
}
a:hover{
  text-decoration: none;
}

body {
  margin: 0;
  font-family: 'CircularStd', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img{
  max-width: 100%;
}

input:focus {
  outline: none;
}