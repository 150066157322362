.empty-cart {
    text-align: center;
}

.empty_cart {
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.empty-cart .black-btn {
    padding: 12px 15%;
    margin-top: 10%;
}

.cart-item {
    background-color: var(--grey);
    padding: 30px;
    padding-bottom: 15px;
}

.keep-shop:hover {
    background-color: rgb(230, 231, 231);
}

.cart-item-img {
    margin-bottom: 20px;
}

.cart-item-style-container {
    display: flex;
    margin: 20px 0;
}

.cart-item-img img {
    width: 100%;
    object-fit: cover;

}

.cart-item-style {
    margin-right: 20px;
}

.cart-item-title-grey {
    color: var(--secondary-light);
}

.cart-item-details {
    font-size: 0.9rem;
}

.cart-item-price {
    font-weight: 900;
}

.cart-menu #empty-cart .empty-img img {
    width: 140px;
    height: 90px;
}

.cart-menu #empty-cart p.sub-title {
    padding: 0 10%;
    font-weight: 400;
}

.bag-empty-txt {
    font-size: 28px;
    font-family: 'Noto Serif', serif;
    font-weight: 700;
}

.cart-item-titleprice {
    display: flex;
    height: 100%;
    justify-content: space-between;
}

.cart-items-container {
    margin-top: 20px;
}

.product-price {
    display: flex;
    justify-content: space-between;
    font-size: 0.8rem;
}

.cart-bot-text {
    font-size: 0.8rem;
}

.cart-prods,
.cart-total {
    align-items: center;
}

p.cart-item-title-grey:first-child {
    margin-right: 5px;
}

.cart-total .cart-item-details {
    font-size: 1.5rem;
    font-weight: 900;
    margin-left: 5px;
}

.cart {
    display: flex;
    height: 100vh;
    align-items: stretch;
}

.cart-main {
    height: 100%;
}

.cart-items {
    position: relative;
}

.cart-items .remove-cart {
    cursor: pointer;
    width: fit-content;
    padding: 2% 4%;
    margin-left: 30px;
}

.cart-items-container .cart-items {
    background-color: var(--grey);
    padding-bottom: 30px;
}

.cart-items-container .cart-items .remove-container {
    position: absolute;
    top: 45%;
    left: -3%;
    width: 106%;
    background-color: white;
    padding: 5%;
    border: 1px solid var(--secondary);
}

.cart-items .remove-container .remove-note {
    margin-bottom: 10%;
}

.cart-items .remove-container .btns {
    justify-content: space-between;
    padding: 2% 3% 0 3%;
    width: 100%;
    display: flex;
}

#cart-item .cart-items-container .cart-item-img img {
    height: auto;
    max-height: 200px;
    object-fit: contain;
    margin: 8% 0;
}

#cart-item .cart-items-container .cart-item-img,
#cart-item .cart-items-container .cart-item-img .App,
#cart-item .cart-items-container .cart-item-img #frame-container {
    height: auto;
    min-height: auto;
}

#cart-item #frame-3d:hover .three_d_box_container {
    transform: none;
}

#cart-item #frame-3d {
    align-items: center;
    top: 0%;
    left: 0%;
}

#cart-item .cart-items-container .cart-item-img #frame-3d {
    align-items: center;
}

.cart-btns .btns {
    display: flex;
    justify-content: space-between;
}

.cart-btns .btns>button {
    width: 48%;
    padding-top: 3%;
    padding-bottom: 3%;
}

.cart-menu {
    padding: 2%;
}

.cart-menu .product-price {
    margin-top: 30%;
}

.overlay .cart-item .cart-item-title {
    font-family: Noto Serif TC;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0px;
    text-align: left;
}

.overlay .cart-item .cart-item-price {
    font-weight: 700;
}

.overlay .cart-item .cart-item-title-grey {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
}

.overlay .cart-item .cart-item-style:first-child {
    margin-right: 20%;
}

.overlay .cart-item .cart-item-style {
    margin-right: 0;
}

.overlay .cart-item .brown {
    font-weight: 400;
}

.overlay .product-price .cart-item-title-grey {
    font-weight: 400;
    font-size: 14px;
}

.overlay .product-price .cart-item-title-grey-total {
    font-weight: 700;
}

.cart-item-details-price {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
    letter-spacing: 0px;
    text-align: center;
    padding-bottom: 3%;
}

.overlay .cart-bot-text {
    font-weight: 400;
    font-size: 12px;
}

.overlay .cart-main .headmedium {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0px;
    text-align: center;
}

.blur {
    opacity: 0.6;
}

.blur-box {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: #6c757d4d;
}

@media screen and (max-width: 575px) {
    .cart-items .remove-container {
        left: 0;
        width: 90%;
        right: 0;
        margin: 0 auto;
    }
}