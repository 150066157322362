.error-page .error-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 5%;
}
@media screen and (max-width: 575px){
    .error-page .error-content{
        margin-top: 20%;
    }
    .error-content .back-btn{
        display: flex;
        justify-content: center;
    }
    .error-page .error-content .title{
        font-family: Noto Serif TC;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: center;
    }
    .error-page .error-content p{
        font-family: 'CircularStd', serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: center;
    }
}
.error-content img{
    width: 200px;
    height: 200px;
}
.error-content .title{
    font-family: Noto Serif TC;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: center;
}
.error-content p{
    font-family: 'CircularStd', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
}
.error-content .back-btn{
    align-items: center;
}
.error-content .btns{
    margin-top: 5%;
}
.error-content .btns .bgsecondary{
    white-space: nowrap;
    min-width: 100%;
}