.object-details .thumb-cont .thumb,
.mixed-media-details .thumb-cont .thumb {
    width: 100%;
    aspect-ratio: 1;
    height: fit-content;
    padding: 0;
}

.mixed-media-banner-img {
    aspect-ratio: 1.835;
}

.mixed-media-listing .frame-ind img,
.object-listing .frame-ind img {
    aspect-ratio: 1;
}

.object-listing .gift-heading p {
    max-width: none;
}

.mixed-media-listing .frame-ind .heart-box .heart-symbol,
.object-listing .frame-ind .heart-box .heart-symbol {
    height: auto;
}

.sdasd img {
    overflow-x: scroll;
}

.object-details .thumb,
.mixed-media-details .thumb {
    border: none;
    opacity: 1;
}

.object-details .thumb.active,
.mixed-media-details .thumb.active {
    border: 0.5px solid #000000;
}

.mixed-media-details .frame-select-box-container,
.object-details .frame-select-box-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    aspect-ratio: 1;
    width: 12%;
    border-radius: 50%;
    gap: 2%;
    border: 1px solid #B8B8B8;
    margin-bottom: 3%;
    cursor: pointer;
}

.mixed-media-details .frame-select-container,
.object-details .frame-select-container {
    grid-gap: 2%;
    gap: 2%;
}

.mixed-media-details .frame-select-box-container.active,
.object-details .frame-select-box-container.active {
    border: 2px solid #404040;
}

.mixed-media-details .frame-select-box,
.object-details .frame-select-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.mixed-media-details .frame-select-box-container .frame-thumbnail,
.object-details .frame-select-box-container .frame-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.overlay-text {
    position: absolute;
    left: 4rem;
    top: 4rem;
}

.tab .description {
    cursor: pointer;
}

.no-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
}

.name-price .sub-heading {
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    max-height: 32px;
}

.frame-list,
.art-list .row {
    column-gap: 2%;
}

.frame-list .frame-ind,
.frame-list .gifting-block {
    margin-left: 0;
    margin-right: 0;
}

.popup-slider {
    column-gap: 8px;
}

.popup-slider .arrow-container {
    width: 40px;
    height: 40px;
}

.popup-slider .arrow-container .arrow {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.zoompopup-container #frame-3d {
    padding: 0;
}

@media screen and (min-width:575px) {

    .object-details .thumb-cont,
    .mixed-media-details .thumb-cont {
        margin-top: 36px;
        justify-content: center;
    }

    .w-sm-80 {
        width: 80% !important;
    }

    .frame-list {
        column-gap: 2%;
    }

    .frame-list .frame-ind {
        margin-left: 0;
    }
}

.add-cart-button.unavailable {
    width: 240px;
}

@media screen and (max-width:575px) {

    .object-details .thumb-cont,
    .mixed-media-details .thumb-cont {
        overflow-x: scroll;
        width: 100%;
        gap: 10px;
        margin: 0;
    }

    .object-details .thumb-cont .thumb,
    .mixed-media-details .thumb-cont .thumb {
        width: 70px;
        min-width: 70px;
        aspect-ratio: 1;
        flex: 1;
        max-width: 70px;
    }

    .mixed-media-listing .gift-heading {
        background-color: #f5f5f5;
    }

    .overlay-text {
        position: absolute;
        left: 1.5rem;
        top: 1.5rem;
    }

    .popup-slider .arrow-container {
        width: 30px;
        height: 30px;
    }

    .popup-slider .arrow-container .arrow {
        width: 90%;
        height: 90%;
    }

    .popup-slider .zoom-img {
        max-width: 83vw;
    }

    .artSearch-close {
        width: 12px;
        height: 12px;
    }

}

@media screen and (max-width:992px) {
    .add-cart-button.unavailable {
        font-size: 14px !important;
    }

    .add-cart-button.unavailable {
        width: 205px;
    }

    .popup-slider .arrow-container {
        width: 30px;
        height: 30px;
    }

    .popup-slider .arrow-container .arrow {
        width: 90%;
        height: 90%;
        object-fit: contain;
    }

    .popup-slider .zoom-img {
        width: 100%;
    }
}

@media screen and (min-width:992px) {
    .w-md-80 {
        width: 80% !important;
    }
}