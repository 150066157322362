.load-btns {
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 4px 11px rgba(29, 31, 34, 0.1);
}

.btn-load.active {
  border-bottom: 2px solid;
}

.btn-load {
  width: 25%;
  text-align: center;
  padding: 15px;
  font-size: 0.8rem;
  position: relative;
}

.checkout-payment .main-heading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-list h5 {
  margin-bottom: 20px;
}

#checkout .checkout-shipping .black-btn {
  width: 100%;
}

#checkout .checkout-payment .black-btn {
  width: 100%;
}

.my-popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}

.total-left {
  font-weight: 400;
}

.country.form-select {
  background-color: white;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 10px 0;
  border-bottom: 1px solid var(--grey);
  margin-bottom: 20px;
  font-size: 15px;
  width: 100%;
}

.checkout-review .cart-item-title-grey {
  font-family: 'CircularStd';
}

.checkout-review .cart-item-details-1 {
  font-family: 'CircularStd';
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  padding: 0;
  color: #1D1F22;
}

.fields-sec input {
  width: 100%;
}

.cart-item-title {
  text-transform: capitalize;
}

.checkout-review .cart-item-style-container {
  margin: 5% 0 0 0;

}

.checkout-review .review-down-container>div {
  width: 50%;
}

.checkout-review .review-down-container .prod-detail .table-list {
  width: 75%;
  justify-content: flex-end;
  margin-left: auto;
}

.checkout-review .review-down-container .discount-row {
  width: 100%;
  align-items: center;
}

.invalid-code {
  margin-left: 5px;
  color: crimson;
}

.place-pay .place-link {
  width: 100%;
}

.checkout-review .cart-item {
  display: flex;
  flex-direction: row;
}

.checkout-review .cart-item .cart-item-img {
  margin-bottom: 0;
}

.review-order .pricetable .table-row {
  position: relative;
}

.review-order .pricetable .table-row .column2 {
  position: absolute;
  top: 4%;
  right: 0;
}

.review-order .pricetable .column2 a {
  font-weight: normal;
  font-size: 12px;
}

.checkout-review .cart-item .cart-item-details {
  width: 100%;
  padding-left: 5%;
}

/* .cart-item-detail-down {
  margin-top: auto;
} */

.flex-row {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.flex-align-center {
  display: flex;
  align-self: center;
  flex-direction: column;
}

.place-pay {
  width: 65%;
}

.prod-detail {
  width: 300px;
}

.total-bord {
  border-color: #F1F2F3 !important;
}

.checkout-review .cart-item-detail-overall {
  display: flex;
  flex-direction: column;
  align-content: space-between;
}

.add-discount {
  background-color: #F1F2F3;
  font-family: 'Noto Serif', serif;
  outline: none;
  border: none;
  height: fit-content;
  padding: 2%;
  flex: auto;
  text-transform: uppercase;
  font-weight: 700;
}

.discount-row img {
  width: 36px;
  height: 36px;
  align-self: center;
  margin-left: 5%;
}

.review-btn {
  padding: 10px !important;
}

.h8 {
  padding-top: 5%;
  font-weight: normal;
  font-size: 16px;
  line-height: 130%;
}

.h9 {
  padding-top: 5%;
  font-weight: normal;
  font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.checkout-review .cart-items-container .checkout-cart-item-img {
  width: 290px;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkout-review .cart-items-container .checkout-cart-item-img .image {
  object-fit: contain;
  max-height: 190px;
}

.checkout-review #frame-3d:hover .three_d_box_container {
  transform: none;
}

.checkout-review #frame-3d {
  align-items: center;
  top: 0%;
  left: 0%;
}

.frame-edit-preview-edit {
  height: 100%;
  width: 100%;
}

.checkout-review .cart-items-container .checkout-cart-item-img, .checkout-review .cart-items-container .checkout-cart-item-img .App, .checkout-review .cart-items-container .checkout-cart-item-img #frame-container {
  height: auto;
  min-height: auto;
}

@media screen and (min-width: 575px) {

  .mobile-only {
    display: none;
  }

  .cart-item-right {
    margin-left: 20%;
  }

  .contact-us .h9 {
    padding-left: 14%;
    padding-top: 0;
  }

  .checkout-review .cart-item-title {
    font-size: 20px;
  }

}

@media screen and (max-width: 575px) {
  .dot.active {
    display: none !important;
  }

  .review_accordion .tabs-mobile .accordion__button {
    font-size: 16px;
  }

  div.checkout-review .cart-item .cart-item-details {
    width: 100%;
    padding-left: 0%;
    padding-top: 5%;
  }

  .checkout-review .cart-items-container .checkout-cart-item-img {
    width: auto;
  }

  div.checkout-shipping .fields-sec .col-sm-6, div.checkout-shipping .col-sm-7 {
    padding-left: 15px;
  }

  .review-order {
    display: none;
  }

  .desk-only {
    display: none;
  }

  .checkout-review .cart-item {
    flex-direction: column;
  }

  .checkout-review .flex-row {
    flex-direction: column !important;
  }

  .contact-us .h9 {
    padding-top: 0;
    padding-left: 7%;
  }

  .order-banner .container {
    padding-left: 0;
    padding-right: 0;
  }

  .checkout-review .cart-item-title {
    font-size: 12px;
  }

  .order-banner .desc {
    font-size: 12px;
  }

  .order-banner .headmedium {
    font-family: 'CircularStd';
    color: #404040;
    font-size: 16px;
  }

  .order-banner .order-nums {
    font-size: 14px;
  }

  .order-banner .order-nums .num {
    font-weight: 700;
    padding-left: 2%;
  }

  #checkout-confirm {
    padding: 0 8%;
  }

  .checkout-container {
    width: 100%;
    margin-top: 10px;
  }

  .checkout-review .cart-item img {
    width: 100%;
    height: auto;
    max-height: 120px;
  }

  #checkout-confirm .place-pay {
    width: 100% !important;
  }

  .checkout-review .discount-row {
    flex-direction: row !important;
    margin: 5% 0;
    width: 100%;
  }

  .checkout-review .prod-detail {
    width: 100%;
    height: 100%;
    padding: 5%;
    background-color: var(--grey);
  }

  .checkout-review .select-default {
    background-color: var(--grey);
    margin: 0 0;
    width: 100%;
    padding: 5% 5%;
    margin-bottom: 10px;
  }

  .confirm-contact {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    margin-left: 12%;
    padding: 5% 0;
  }

  .place-pay {
    flex-direction: column !important;
    width: 100%;
  }

  #checkout .place-pay .black-btn {
    width: 100%;
  }

  .checkout-review .flex-align-center {
    width: 100%;
  }

  #checkout .checkout-shipping {
    padding-left: 7%;
    padding-right: 7%;
  }

  #checkout .checkout-payment {
    padding-left: 7%;
    padding-right: 7%;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1320px) {
  .checkout-review .cart-item img {
    width: 190px;
    height: 190px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1200px) {
  .h8 {
    font-size: 14px;
  }

  .h9 {
    font-size: 11px;
  }

  .review-order {
    padding: 0 !important;
  }

  .pricetable {
    padding: 10px !important;
  }

  .prod-detail {
    width: 200px;
  }

  .checkout-review .cart-item img {
    width: 180px;
    height: 180px;
  }
}

@media screen and (min-width: 576px) and (max-width: 800px) {
  .checkout-review .cart-item img {
    width: 150px;
    height: 150px;
  }
}

@media screen and (min-width: 576px) and (max-width: 1000px) {
  .h8 {
    font-size: 12px;
  }

  .h9 {
    font-size: 8px;
  }

  .review-order {
    padding: 0 !important;
  }

  .pricetable {
    padding: 10px !important;
  }

  .prod-detail {
    width: 150px;
  }

  .checkout-review .cart-item img {
    width: 170px;
    height: 170px;
  }
}

#checkout input::-webkit-input-placeholder {
  /* Edge */
  font-size: 0.8rem;
  color: var(--blackmain);
  font-weight: 500;
  font-family: CircularStd;
}

#checkout input::-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 0.8rem;
  font-weight: 500;
  color: var(--blackmain);
  font-family: CircularStd;
}

#checkout input::placeholder {
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--blackmain);
  font-family: CircularStd;
}

.but-bot-text {
  color: var(--blackmain);
  font-size: 0.7rem;
  text-align: center;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  white-space: nowrap;
}

.tick-box {
  width: 20px;
  height: 20px;
  border: 1px solid var(--secondary);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tick-box img {
  display: none;
}

.tick-box.active img {
  display: block;
}

.tick-box.active {
  background-color: var(--secondary);
}

.select-default {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.select-default p {
  font-size: 0.8rem;
}

.select-checkbox.active {
  background-color: var(--grey);
}

.select-checkbox {
  display: flex;
  align-items: center;
  padding: 10px 5px;
  font-size: 0.8rem;
  font-weight: 700;
  cursor: pointer;
}

.round {
  height: 19px;
  width: 19px;
  border: 1px solid;
  border-radius: 50%;
  display: inline-block;
  margin: 10px;
}

.select-checkbox.active .round {
  border: none;
}

.round img {
  display: none;
  object-fit: contain;
  height: 20px;
  width: 20px;
}

.select-checkbox.active .round img {
  display: block;
}

.method-checkbox {
  margin: 20px 0;
}

#checkout .black-btn {
  padding: 15px 20px;
  width: 100%;
}

#checkout .new-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px 32px;
}

.otp-input-container {
  gap: 24px;
}

.otp-input .otp-input-tag {
  font-style: normal;
  font-weight: 390;
  font-size: 16px;
  line-height: 26px;
  width: 45px !important;
  height: 42px;
}

.checkout-login .otp-button {
  margin-top: 20px;
}

.checkout-login .tick-box img {
  width: 11px;
  height: 9px;
}

.checkout-login .tick-box {
  width: 24px;
  height: 24px;
}

.checkout-login .accept {
  font-size: 18px;
  line-height: 130%;
  color: #1D1F22;
}

.checkout-sub {
  font-weight: 700;
  font-size: 0.9rem;
  margin-top: 10px;
}

.checkout-shipping .fields-sec .col-sm-6, .checkout-shipping .col-sm-7 {
  padding-left: 0;
}

.checkout-shipping .fields-sec>input {
  margin-top: unset;
}

/* AACCORDION */
.review_accordion .accordion__button {
  background-color: #fff;
}

.review_accordion .accordion {
  border: none;
}

.review_accordion .accordion__button {
  font-weight: 900;
  font-size: 0.8rem;
}

.review_accordion .accordion__panel {
  padding: 0;
}

/* PRICE TABLE(REVIEW) */
.table-list {
  margin-top: 20px;
}

.pricetable {
  padding: 20px 30px 20px 30px;
  background-color: var(--grey);
}

.pricetable h6 {
  font-weight: 600;
}

.table-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.table-row.total {
  padding-top: 15px;
  border-top: 1px solid white;
}

.column1 {
  font-size: 14px;
  font-weight: 400;
}

.column2 {
  font-weight: 700;
  font-size: 14px;
}

.select-default.grey-box {
  margin: 0 0 20px 0;
  padding: 0 30px 30px 30px;
  background-color: var(--grey);
}

@media screen and (min-width: 575px) {
  .checkout-main {
    justify-content: space-between;
    margin: 3% 8%;
  }

  .checkout-container {
    width: 66%;
  }

  .total-right {
    font-weight: 700;
    color: black;
  }

  .side-desk {
    width: 30%;
    height: min-content;
  }

  .side-desk .d-flex {
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .load-btns {
    box-shadow: none;
    border-bottom: 1px solid var(--grey);
  }

  .but-bot-text {
    text-align: left;
    display: block;
  }

  .inp-fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .profile-input {
    width: 45%;
  }

  #checkout .checkout-payment .but-bot-text {
    padding-top: 1%;
  }

  #checkout .checkout-shipping .but-bot-text {
    padding-top: 1%;
  }
}

#checkout-confirm .review-down-container .prod-detail .table-list {
  margin-left: 0;
}

#checkout-confirm .black-btn {
  width: 100%;
}

#checkout-confirm .place-pay {
  width: 75%;
}

#checkout-confirm .but-bot-text {
  padding-left: 8%;
}

.confirm-order .column2 {
  display: none;
}

.ordered-banner {
  background-color: #F1F2F3;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
}

.ordered-banner p {
  text-align: center;
}

.rev-stats {
  margin-top: 5%;
  width: 16px;
  height: 16px;
  margin-right: 5%;
}

.dot {
  position: absolute;
  display: none;
  left: 50%;
  height: 8px;
  width: 8px;
  background-color: black;
  bottom: -5px;
  border-radius: 10px;
}

.dot.active {
  display: block;
}


.contact-us {
  padding: 10px;
}