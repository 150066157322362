@font-face {
    font-family: 'bradMed';
    src: local('MyFont'), url(../../assets/fonts/Brandon_med.otf) format('opentype');
  }


.login-grey {
    font-family: Circular Std;
    text-align: center;
    font-size: 0.9rem;
    color: grey;
    margin-top: 10%;
    font-style: normal;
}

.login .frgt-pwd {
    cursor: pointer;
}

.remember-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    letter-spacing: 0.3px;
    color: #1A1A1A;
}

.forget_pwd {
    text-align: center;
}

.login::-webkit-input-placeholder {
    font-weight: 400;
}

.login::-moz-placeholder {
    font-weight: 400;
}

.login::-ms-input-placeholder {
    font-weight: 400;
}

.login .remember-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}

.login-close {
    position: absolute;
    right: 17px;
    top: 17px;
}

.login .eye-container img {
    width: 20px;
    height: 20px;
    min-width: 20px;
}

.login .eye-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    border: 1px solid #4F4F4F;
    border-left: 0px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-right: 10px;
}

.login .eye-input {
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

@media screen and (max-width: 575px) {
    .login .select-default {
        display: none;
    }

    .login .black-btn {
        width: 100%;
        margin-top: 8%;
    }

    .login {
        padding-left: 10%;
        padding-right: 10%;
    }

    .login .profile-input {
        width: 100%;
        margin-bottom: 12%;
    }

    .login .headmedium {
        font-family: Noto Serif TC;
        margin-bottom: 10%;
    }
}

@media screen and (min-width: 575px) {

    .awrContainer{
        width: 373px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
    }

    .login .mobile-only {
        display: none;
    }

    .popup-container.login-popup {
        width: 465px;
    }

    .login .login-heading {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 23px;
        text-align: center;
        margin-top: 9px;
        /* Secondary/Dark Color */
        color: #54595E;
        margin-bottom: 8%;
    }

    .login-grey {
        font-family: 'CircularStd';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: center;
        color: black;
    }

    .login .tick-box {
        cursor: pointer;
        width: 12px;
        height: 12px;
        border-radius: 2px;
    }

    .change-mode {
        font-family: 'CircularStd';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0px;
        text-align: center;

    }

    .popup-container .profile-input {
        margin-bottom: 4%;
    }

    .profile-input {
        height: 44px;
        border: 1px solid #4F4F4F;
        border-radius: 8px;
    }
}

.popup-container.login-popup {
    border-radius: 8px;
}

.change-mode {
    color: var(--blackmain);
    text-align: center;
    font-size: 0.8rem;
    font-weight: 900;
    margin-top: 4%;
    cursor: pointer;
}

.profile-input::-webkit-input-placeholder {
    /* Edge */
    font-weight: 400;
    color: #4F4F4F;
    font-size: 16px;
    /* font-style: "Brandon Grotesque"; */
    font-family: "bradMed";
}

.profile-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-weight: 400;
    color: #4F4F4F;
    font-size: 16px;
    /* font-style: "Brandon Grotesque"; */
    font-family: "bradMed";
}

.profile-input::placeholder {
    font-weight: 500;
    color: #4F4F4F;
    font-size: 16px;
    /* font-style: "Brandon Grotesque"; */
    font-family: "bradMed"!important;
}

.login-page-change {
    color: #4F4F4F;
}

.font-500 {
    font-weight: 600;
}

.bottom-link {
    margin-top: 44px;
}

.login .line-break {
    font-size: 10px;
    /* font-size: 12px; */
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}



/* awr */

.font-400{
    font-weight: 500;
}


  .profile-inputAwr::placeholder {
    display: none;
  }

  .mt-40px{
      margin-top: 36px;
  }

  .ml-13p{
    margin-left: 2.75rem;
  }

  .profile-inputAwr{
    padding: 10px 20px!important;
  }