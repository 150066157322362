.loader-grey {
    overflow: hidden;
    -webkit-animation-duration: 1.2s;
    animation-duration: 1.2s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-name: simmer_placeholder;
    animation-name: simmer_placeholder;
    background: #f6f7f8;
    background: linear-gradient(90deg, #eee 2%, #ddd 18%, #eee 33%);
    background-size: auto;
    background-size: auto;
    background-size: 1300px;
}

.art-list-loader {
    height: 300px;
}

.gallery-list-loader {
    height: 400px;
}

.select-loader-row {
    gap: 2%;
    margin-bottom: 40px;
}

.type-select-loader-box {
    height: 48px;
    width: 48%;
}

.frame-loader-row {
    gap: 2%;
    margin-bottom: 40px;
}

.frame-loader-box {
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
    border-radius: 50%;
    width: 15%;
}


.size-loader {
    gap: 2%;
    margin-bottom: 40px;
}

.size-loader-box {
    width: 32%;
    height: 36px;
    margin-bottom: 12px;

}

.second-head-load {
    height: 24px;
    width: 75%;
    margin-bottom: 12px;
}

.second-head-load {
    height: 28px;
    margin-bottom: 3%;
}

.brandon-text-load {
    height: 16px;
    width: 75%;
    margin-bottom: 12px;
}

.gallery-image-loader {
    width: 100%;
    aspect-ratio: 1;
}

.price-add-loader {
    height: 40px;
}

.add-cart-loader {
    height: 36px;
}

.pin-code-input-loader {
    width: 80%;
    margin-bottom: 10px;
    height: 40px;
}

.gallery-wall .pin-code-input-loader {
    margin-top: 6%;
}

.gift-image-loader {
    aspect-ratio: 0.67;
    height: 90%;
}

.search-loader {
    height: 5px;
}

.search-loader.last-load {
    width: 75%;
}

@media screen and (max-width: 575px) {
    .art-list-loader {
        max-height: 165px;
    }

    .gallery-list-loader {
        max-height: 220px;
        height: 220px;
    }

    .mob-row-gap {
        gap: 0.5%;
    }
}

@keyframes simmer_placeholder {
    0% {
        background-position: -468px 0;
    }

    100% {
        background-position: 468px 0;
    }
}