

.design-price{
right: 20px;
bottom: 20px;
background: #FFFFFF;
opacity: 0.8;
border-radius: 9px;
padding: 5px 16px;
}

.designbenefit-container{
    background-color: #F8F8F8;
    padding: 35px 35px 48px;
}

.design-dot{
    font-size: 1.75rem;
}

.designbenefit-container{
    list-style-position: inside;
    list-style-type: circle;
}

.designbenefit-container li{
    padding-bottom: 6px;
}

.design-button{
    cursor: pointer;
    background-color: #404040;
    color: white;
    width: 300px;
    font-size: 18px;
    line-height: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 36px;
    font-weight: 400;
}

.design-subtitle1{
    font-weight: 700;
    font-size: 28px;
    line-height: 30px;
    color: #404040;
    padding-bottom: 20px;
}

.design-subcontainer{
    width: 1034px;
}

.design-subcontainer1{
    padding-top: 20px;
}

.designlisting{
    font-weight: 400;
    color: #404040;
    font-size: 18px;
    line-height: 30px;
}

.design-note{
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    padding-top: 40px;

}

.designcount{
    width: 40px;
    height: 40px;
    border: 1px solid #000000;
    border-radius: 5rem;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    /* padding: 0px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;

}

.designcount-title{
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    margin-bottom: 20px;
}
.designcount-sub{
    font-weight: 420;
    font-size: 20px;
    line-height: 26px;
}

.designcount-container{
    width: 360px;
    margin-top: 40px;
}

.design-steps{
    padding: 160px 0px 120px;
    max-width: 1440px;
    /* text-align: center; */
    margin-left: auto;
    margin-right: auto;
}

.designbenefit-maincontainer{
    background-color: #F8F8F8;
}

.design-taggs{
    margin-bottom: 30px;
    height: 47px;
}

.design-tag{
    width: 49px;
    height: 61px;
    margin-bottom: 15px;
}

.design-truck{
    width: 97px;
    height: 43px;
    margin-bottom: 32px;
}

.design-thumb{
    width: 53px;
    height: 47px;
    margin-bottom: 29px;
}

/* .designimg2{
    margin-bottom: 24px;
} */

.designcerti-container{
    background-color:  rgba(241, 242, 243, 0.4);
    padding: 80px 100px;
}

.designmotto{
    font-weight: 700;
    font-size: 28px;
    line-height: 33px;
    margin-bottom: 12px;
}


@media only screen and (max-width:1250px){
    .design-subcontainer{
        width: 100%;
    }
}
@media only screen and (max-width: 992px){
    .design-subcontainer{
        width: 100%;
    }
    .design-button{
        width: 100%;
    }
    .design-subtitle1{
        font-size: 20px;
    }
    .designlisting{
        font-size: 16px;
      }
      .design-button{
        font-size: 16px;
      }
      /* .designcount-title{
        font-size: 24px;
        line-height: 32px;
      } */
}
@media only screen and (max-width: 576px){

    .design-steps .partner-title{
        padding-bottom: 10px;
    }

    .designcount-container{
        width: 100%;
    }
    .designcerti-container{
        padding: 48px 48px 0px;
    }

    .partner-container .bullet-cont {
        margin-bottom: 30%;
    }

    .designbenefit-maincontainer{
        margin-bottom: 40px;
    }
  .designservice-container{
    padding: 0 24px;
  }

  .design-note{
    padding: 0;
  }

  .design-steps{
    padding: 60px 0 80px;
  }

  .designcount-container{
    margin-top: 30px;
  }
 
}