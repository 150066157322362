.partnerapply-container{
    padding-top: 75px;
    padding-bottom: 200px;
}
.partner-input{
    height: 52px;
    margin-bottom: 20px;
    padding: 0 16px;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    width: 695px;
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
}

.partner-inputlabel{
    color: #ACACAC;
    font-weight: 400;
    margin-bottom: 4px;
    font-size: 16px;
    line-height: 23px;
    width: 695px;
    /* width: 100%; */
    margin-left: auto;
    margin-right: auto;
}

.partner-subtitle1{
    color:#404040;
}


.partner-input::placeholder{
    color: #ACACAC;
    font-family: "Brandon Grotesque";
    font-weight: 400;
}

.partner-submit{
    height: 72px;
    color: #F5F5F5;
    background-color: #4F4F4F;
    border-radius: 8px;
    border: none;
    width: 695px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
}

@media only screen and (max-width:1300px){
   
}
@media only screen and (max-width:992px){
    .partner-input{
        width: 625px;
    }
    .partner-submit{
        width: 90%;
    }
}


@media only screen and (max-width:576px){
    .partnerapply-container{
      padding: 40px 20px 100px;
    }
    .partner-input{
        width: 100%;
        height: 40px;
    }
    .partner-submit{
        width: 100%;
        height: 40px;
    }
}