.box-wrap {
  display: flex;
  flex-wrap: wrap;
}

.sub-title {
  font-size: 12px;
  text-align: center;
}

.delivery-box {
  width: 100%;
}

.size_box {
  margin: 5% 0 3% 0;
}

.mobilehide {
  display: none;
}

.size_title {
  margin: 20px 0 10px 0;
  font-size: 20px;
  line-height: 20px;
  color: rgba(64, 64, 64, 1);
}

.crop-done {
  display: flex;
  justify-content: center;
}

.size-tab {
  border: 1px solid #404040;
  text-align: center;
  color: #404040;
  padding: 5px;
  width: 32%;
  cursor: pointer;
  margin-bottom: 20px;
  margin-right: 2%;
}

.size-tab.selected {
  background-color: #404040;
  color: white;
}

.paper-type {
  text-align: center;
  cursor: pointer;
}

.paper-type img {
  border: 4px solid #fff;
  max-height: 50vh;
}


.price-cont {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* margin: 20px 0; */
}

.price-cont a.browntext {
  font-size: 8px;
}

.browntext {
  color: #6c3f2c;
}

.price-cont h2 {
  margin-bottom: 0;
}

.price-cont p.size {
  font-size: 12px;
  margin-bottom: 10px;
  font-weight: 900;
}

.crop-section .headxsmall {
  text-align: center;
  font-size: 14px;
  margin-bottom: 12%;
}

.crop-text {
  display: flex;
  margin-top: 10px;
}

.crop-text p {
  margin-right: 10px;
}

.sizeSelect-container .paper-type p {
  font-size: 24px;
  font-weight: 400;
}

.sizeSelect-container .sizeSelect-title {
  font-size: 36px;
}

@media screen and (max-width: 575px) {

  .size-tab {
    font-size: 10px;
    width: 32%;
  }

  .size_title {
    font-size: 8px;
    line-height: 8px;
    margin: 2px;
  }

  .select_size .price-cont .price_cart {
    width: 100%;
  }

  .sizeSelect-container .paper-type p {
    font-size: 12px;
  }

  .sizeSelect-container .sizeSelect-title {
    font-size: 16px;
  }

  .sizeTitle {
    font-size: 12px;
    font-weight: 700;
  }
}

.select_size {
  flex-wrap: wrap;
}

.select_size .price_cart {
  width: 50%;
}

.choose-head {
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  color: #1d1f22;
}

.sizes-cols p {
  color: #8c8c8c;
}

.choose-frame {
  text-decoration: underline;
}

.choose-frame:hover {
  text-decoration: underline;
}

.choose-frame img {
  transform: rotate(180deg);
  height: 26px;
  padding-right: 2%;
}

.section-line {
  height: 2px;
  background: #f1f2f3;
  margin: 2% 0;
}

.shop_art .frame-edit-preview-container {
  background-color: rgb(240, 240, 240);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shop_art .frame-edit-preview-container .frame-edit-preview {
  height: 350px;
  object-fit: contain;
}

.shop_art .main .shop-art-preview-img {
  width: 100%;
  height: 450px;
  position: absolute;
  top: 0;
  left: 0;
}

.paper-cont {
  max-height: 70vh;
  width: auto;
}

.shop_art .main .shop-art-preview-img .frame-edit-preview {
  width: auto;
  height: auto;
  max-width: 200px;
  object-fit: contain;
  display: flex;
  margin-top: 10%;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 20%;
}

.shop_art .size-tab {
  width: 31.33%;
  margin-right: 5%;
}

.zoom-img {
  max-height: 75vh;
  max-width: 90vw;
}

.zoom-close {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.zoom-button {
  width: 20px;
  height: 20px;

}

.zoom-button-container {
  background-color: white;
  border-radius: 50%;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 4%;
  right: 4%;
  cursor: pointer;
}

.shop_art .sizeTitle {
  margin-left: 0;
}

.shop_art .frame_prev_mob .show-img {
  width: 90%;
  height: 90%;
  object-fit: contain;
  padding-bottom: 220px;
}

.size-tab:nth-child(3n) {
  margin-right: 0;
}

@media screen and (min-width: 575px) {
  .cropsec {
    text-align: center;
    /* margin-bottom: 5%; */
    width: 100%;
  }

  .sizeTitle {
    width: 98%;
    margin-left: -1%;
  }

  .sizeTitle {
    font-size: 20px;
    font-weight: 700;
  }

  .size-container .sub-title {
    display: none;
  }

  #select_size h2.headmedium {
    font-size: 2rem;
  }

  .select_size h2.headmedium {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 1rem !important;
  }

  .select_size.img-sec {
    width: 40%;
  }

  .select_size.header-margin {
    margin-top: 2rem;
  }

  .select_size {
    padding: 0 3%;
    width: 70%;
  }

  .price-cont a.browntext {
    font-size: 13px;
  }
}

/* awr */
.art-preview-typeSelect {
  padding: 8%;
}

.crop-section>.heading {
  font-family: "GT Super Bold";
  color: rgba(29, 31, 34, 1);
  font-size: 36px;
  line-height: 50px;
}

.cropContainer {
  background-color: rgba(245, 245, 245, 0.8);
}

.cropSub {
  font-size: 24px;
  line-height: 39px;
}

.cropBtn {
  font-size: 20px;
  line-height: 26px;
}

.cropBack {
  text-decoration: underline;
  color: rgba(64, 64, 64, 1);
  font-size: 16px;
  line-height: 26px;
}

.selectBackIcon {
  width: 20px;
  height: 20px;
}

.selectBack {
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
}

.sizeSelect-container {
  background-color: rgba(245, 245, 245, 0.8);
}

.sizeSelect-title {
  color: rgba(29, 31, 34, 1) !important;
}

@media screen and (max-width: 575px) {
  .crop-section>.heading {
    font-size: 20px;
    line-height: 24px;
  }

  .cropSub {
    font-size: 12px;
    line-height: 20px;
  }

  .cropBtn {
    font-size: 12px;
    line-height: 18px;
  }

  .cropBack {
    font-size: 8px;
    line-height: 13px;
  }

  .zoom-button-container {
    width: 30px;
    height: 30px;
  }
  .shop_art .frame-edit-preview-container{
    /* height: auto; */
    height:300px
  }
  .shop_art .frame-edit-preview-container img{
    height: auto;
  }
  .shop_art .frame-edit-preview-container .frame-edit-preview{
    height: auto;
    max-height: 230px;
  }
}