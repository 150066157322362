:root {
  --secondary: #404040;
  --white: #ffffff;
}

/* New Home Screen */
@font-face {
  font-family: "Brandon Grotesque";
  src: url(../../assets/fonts/Brandon_reg.otf);
}

@font-face {
  font-family: "GT Super Ds Trial";
  src: url(../../assets/fonts/GT-Super-Display-Regular-Trial.otf);
}

@font-face {
  font-family: "GT Super Bold";
  src: url(../../assets/fonts/GT-Super-Display-Bold-Trial.otf);
}

.GT-bold {
  font-family: "GT Super Bold";
}

.dark-main {
  color: #1d1f22;
}

.shop-art-banner {
  height: 100%;
  width: 100%;
  position: relative;
  background: linear-gradient(180deg,
      #fff,
      #fff 70%,
      rgba(245, 245, 245, 0.8) 0,
      rgba(245, 245, 245, 0.8));
}

.shop-art-banner .button {
  bottom: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
}

.shop-art-banner .slick-arrow {
  display: none !important;
}

.shop-art-banner .slick-dots {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
}

.font-12 {
  font-size: 12px !important;
}

.font-10 {
  font-size: 10px !important;

}

.shop-art-banner .slick-dots li button {
  color: white;
}

.shopart-banner-img {
  width: 100%;
  padding-top: 56.94%;
  position: relative;
  content: "";
  display: block;
}

.shopart-banner-img img {
  position: absolute;
  /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Make the picture taking the size of it's parent */
  width: 100%;
  /* This if for the object-fit */
  height: 100%;
  /* This if for the object-fit */
  object-fit: cover;
  /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.start-frame-banner {
  width: 100%;
  position: relative;
}

.mixed-media-banner {
  margin: 60px 0px;
}

.start-frame-banner .button {
  bottom: 0;
  top: 0;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
}

.start-frame-banner img {
  position: absolute;
  /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Make the picture taking the size of it's parent */
  width: 100%;
  /* This if for the object-fit */
  height: 100%;
  /* This if for the object-fit */
  object-fit: cover;
  /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.brandon {
  font-family: "Brandon Grotesque";
}

.GT {
  font-family: "GT Super Ds Trial" !important;
}

.three-head {
  padding: 6% 9% 6% 9%;
  font-size: 32px;
  background: rgba(245, 245, 245, 0.8);
}

.view-store-banner {
  width: 100%;
  position: relative;
  height: 100%;
  background-color: rgba(245, 245, 245, 0.8);
}

.view-store-banner-2 {
  background-color: var(--grey);
}

.viewstore-banner-img {
  width: 100%;
  padding-top: 45.4%;
  position: relative;
  content: "";
  display: block;
  background-color: var(--grey);
}

.viewstore-banner-img img {
  position: absolute;
  /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Make the picture taking the size of it's parent */
  width: 100%;
  /* This if for the object-fit */
  height: 100%;
  /* This if for the object-fit */
  object-fit: cover;
  /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
}

.viewstore-banner-img div {
  position: absolute;
  /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Make the picture taking the size of it's parent */
  width: 100%;
  /* This if for the object-fit */
  height: 100%;
  /* This if for the object-fit */
  object-fit: cover;
  /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
  background-size: 100% 100% !important;
}

.art-love-container .art-love.lower-art-love {
  background-color: rgba(245, 245, 245, 0.8);
}

.view-store-banner .slick-arrow {
  display: none !important;
}

.curated-row img {
  height: 80px;
}

.curated-row p {
  /* margin-top: 60px; */
}

.flg-ship-container {
  position: absolute;
  bottom: 0;
  top: 0;
  padding-top: 8%;
  width: 100%;
}

.view-store-banner .slick-dots {
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
}

.bgcolor-pages {
  background: rgba(245, 245, 245, 0.8);
}

.bg-black {
  background-color: #000000;
}

.art-carousel .art-carousel-item .image {
  width: 100%;
  position: relative;
}

.art-carousel .art-carousel-item .image:after {
  content: "";
  display: block;
  padding-bottom: 100%;
  /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

.art-carousel .art-carousel-item .art-head {
  font-size: 18px;
  padding-bottom: 7%;
  margin-bottom: 1%;
}

.art-carousel-item .art-head {
  font-size: 18px;
  font-weight: 500;
}

.art-carousel .art-carousel-item .image img {
  position: absolute;
  /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Make the picture taking the size of it's parent */
  width: 100%;
  /* This if for the object-fit */
  height: 100%;
  /* This if for the object-fit */
  object-fit: cover;
  /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.gallery-wall-carousel .art-carousel-item .image {
  width: 100%;
  position: relative;
}

.gallery-wall-carousel .art-carousel-item .image:after {
  content: "";
  display: block;
  padding-bottom: 100%;
  /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

.gallery-wall-carousel .art-carousel-item .image img {
  position: absolute;
  /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Make the picture taking the size of it's parent */
  width: 100%;
  /* This if for the object-fit */
  height: 100%;
  /* This if for the object-fit */
  object-fit: cover;
  /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.art-carousel-item {
  background: #ffffff;
  border: 1px solid #f1f2f3;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.art-carousel {
  height: 100%;
}

.art-love {
  font-family: "GT Super Ds Trial";
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 140%;
  text-align: center;
  /* Secondary Main */
  color: #404040;
}

.start-frame-banner-img-container {
  width: 100%;
  padding-top: 49.3%;
  position: relative;
  content: "";
  display: block;
}

.frame-carousel .descr {
  font-size: 13px;
  display: table-cell;
  vertical-align: bottom;
  height: 26px;
  text-overflow: hidden;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 13px;
  max-height: 26px;
  -webkit-box-orient: vertical;
}

@media screen and (max-width: 1400px) and (min-width: 575px) {
  .frame-carousel .descr {
    max-height: 39px;
    height: 39px;
  }
}

.special-padd {
  padding-bottom: 3%;
  padding-top: 5%;
}

.frame-carousel .frame-carousel-img {
  height: 300px;
  width: 100%;
}

.frame-carousel .art-carousel-item .image {
  width: 100%;
  position: relative;
}

.frame-carousel .art-carousel-item .image:after {
  content: "";
  display: block;
  padding-top: 140%;
  /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
}

.frame-carousel .art-carousel-item .image img {
  position: absolute;
  /* Take your picture out of the flow */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /* Make the picture taking the size of it's parent */
  width: 100%;
  /* This if for the object-fit */
  height: 100%;
  /* This if for the object-fit */
  object-fit: cover;
  /* Equivalent of the background-size: cover; of a background-image */
  object-position: center;
}

.art-carousel .art-carousel-img {
  height: 230px;
  width: 100%;
}

#nhome .bullet {
  background: rgba(245, 245, 245, 0.8);
  padding: 2% 0;
}

#nhome .bullet .bullet-cont .head {
  font-family: "GT Super Ds Trial";
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 120%;

  /* or 34px */
  text-align: center;

  /* Dark Main */
  color: #1d1f22;
}

.bullet-cont img {
  max-height: 90%;
  vertical-align: bottom;
  display: table-cell;
}

.art-love-container {
  background: rgba(245, 245, 245, 0.8);
  padding: 6% 0;
}

.custom-frame-container {
  background: rgba(245, 245, 245, 0.8);
  padding: 7% 0;
}

.custom-frame-container .art-love {
  line-height: 100%;
}

.custom-frame-container .steps>.row.one {
  padding-left: 0;
}

.ft-slick__dots--custom {
  height: 13px;
  width: 13px;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: relative;
}

.slick-active .ft-slick__dots--custom {
  height: 13px;
  width: 13px;
  background-color: #e5e7e9;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (max-width: 1200px) and (min-width: 575px) {
  .custom-frame-container .steps {
    padding: 0 !important;
  }
}

@media screen and (max-width: 1300px) and (min-width: 575px) {
  .custom-frame-container .steps {
    padding: 0 !important;
  }

  .footer-social-cont .footer-head {
    font-size: 16px;
  }
}

@media screen and (max-width: 1000px) and (min-width: 575px) {
  .custom-frame-container {
    padding: 5% 2%;
  }

  .flg-ship-container .flg-ship {
    font-size: 36px;
  }

  .curated-row p {
    font-size: 24px;
    line-height: 140%;
  }
}

.custom-frame-container .steps .row {
  display: flex;
  align-items: center;
}

.custom-frame-container .steps>.row {
  width: auto;
  white-space: nowrap;
  padding-left: 3.8%;
}

.custom-frame-container .steps .three .brandon {
  white-space: nowrap;
}

.banner-button {
  font-family: "Brandon Grotesque";
  width: auto;
  text-transform: uppercase;
  height: fit-content;
  padding: 1% 3%;
  background-color: #ffffff;
  font-style: normal;
  font-weight: 420;
  font-size: 21px;
  line-height: 100%;
  margin: auto;
  /* or 27px */
  display: table;
  align-items: center;
  text-align: center;
  z-index: 3;
  color: #000000;
}

.banner-button:hover {
  color: #000000;
  background-color: transparent;
  border: 1px solid #000000;
}

.lst-banner-button {
  font-family: "Brandon Grotesque";
  width: fit-content;
  height: fit-content;
  padding: 1% 3%;
  background-color: #ffffff;
  font-style: normal;
  font-weight: 420;
  font-size: 21px;
  line-height: 130%;
  /* or 27px */
  display: flex;
  align-items: center;
  text-align: center;

  color: #000000;
}

.lst-banner-button:hover {
  color: #ffffff;
  background-color: transparent;
  border: 1px solid #ffffff;
}

.custom-frame-container .steps {
  justify-content: center;
}

.custom-frame-container .steps .count {
  border: 1px solid #000000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-frame-container .steps .one .count {
  font-weight: 900;
}

.custom-frame-container .steps .detail {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
}

.flg-ship {
  font-family: "GT Super Ds Trial";
  font-style: normal;
  font-weight: bold;
  font-size: 52px;
  line-height: 120%;
  color: #ffffff;
}

.slick-arrow.slick-disabled {
  display: none !important;
}

.flg-ship-p {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 140%;
  color: #ffffff;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 1;
}

.frame-cards-container .headnormal {
  color: #404040;
}

.bullet-cont {
  align-items: center;
}

#nhome .slick-dots li.slick-active button:before {
  color: White !important;
  width: 16px;
  height: 16px;
  font-size: 14px !important;
  border: none !important;
  margin-bottom: 5px;
}

#nhome .slick-dots {
  display: flex !important;
  align-items: center !important;
  justify-content: center;
  z-index: 2;
}

#nhome .slick-dots li.slick-active {
  align-items: center !important;
  display: flex !important;
}

#nhome .slick-slide {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out !important;
  -webkit-transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out !important;
}

/* #nhome .shop-art-banner .slick-list, #nhome .view-store-banner .slick-list {
  z-index: 2;
} */

.bullet-cont .second-head {
  margin-top: 1%;
}

.bullet-cont .other-head {
  margin-top: 6%;
}

.slick-list .slick-track {
  scroll-behavior: smooth;
}

.frame-card-title {
  font-family: "Noto Serif", serif;
  text-align: center;
  font-size: 18px;
  line-height: 160%;
  font-weight: 600;
  color: #1d1f22;
}

.frame-card-sub {
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  line-height: 130%;
  margin-top: 5px;
  color: #43464e;
}

.steps {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.steps .one {
  width: 28%;
  flex-wrap: nowrap;
}

.steps .two {
  flex-wrap: nowrap;
  width: 28%;
}

.steps .three {
  flex-wrap: nowrap;
  width: 44%;
}

@media screen and (max-width: 1400px) {
  .custom-frame-container .steps .count {
    width: 30px;
    height: 30px;
    font-size: 14px;
  }

  .custom-frame-container .steps {
    font-size: 14px;
  }
}

@media screen and (max-width: 1280px) and (min-width: 575px) {
  .frame-card-sub {
    font-size: 12px;
  }
}

@media screen and (max-width: 575px) {
  .main-heading {
    font-family: "GT Super Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 140%;
  }

  .second-heading {
    font-family: "GT Super Bold";
    font-style: normal;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #1D1F22;
  }

  .frame-card-title {
    font-size: 12px;
  }

  .headnormal {
    font-size: 16px;
  }

  .art-love-details {
    margin-top: 12px;
    margin-bottom: 4px;
  }

  .slick-arrow {
    width: 25px;
    height: 25px;
    z-index: 2;
  }

  .mob-font-10 {
    font-size: 10px !important;
  }

  .mob-font-10.detail{
    margin-top: 4px;
  }

  .start-frame-banner {
    background-size: 100% 100%;
  }

  .sub-heading {
    font-weight: 390;
    font-size: 16px;
    line-height: 160%;
  }

  .art-love-container {
    padding: 6% 0;
  }

  .art-carousel .art-carousel-item .art-head {
    font-size: 12px;
    padding-bottom: 7%;
    margin-bottom: 1%;
  }

  .custom-frame-container .steps {
    justify-content: space-around;
  }

  .custom-frame-container .steps .one,
  .custom-frame-container .steps .two,
  .custom-frame-container .steps .three {
    width: 100%;
  }

  .custom-frame-details-only {
    margin-top: 10px;
  }

  .gallery-love-heading {
    margin-bottom: 10px !important;
  }

  .start-frame-banner {
    background-size: 100% 100%;
  }

  .art-carousel-item .art-head {
    font-size: 12px;
  }

  #nhome p {
    font-size: 8px;
  }

  #nhome .bullet .bullet-cont h2.head {
    font-size: 16px;
  }

  .art-love {
    font-size: 16px;
  }

  .curated-row img {
    height: 40px;
  }

  #nhome .curated-row p {
    line-height: 120%;
    font-size: 12px;
  }

  .flg-ship {
    font-size: 16px;
  }

  .banner-button {
    font-size: 12px;
    font-weight: 700;
    padding: 2% 4%;
  }

  #nhome .bullet-cont p {
    font-size: 12px;
    font-weight: 700;
  }

  .lst-banner-button {
    font-size: 8px;
  }

  .frame-carousel .frame-carousel-img {
    height: 190px;
  }

  .art-carousel .art-carousel-img {
    height: 130px;
  }

  .custom-frame-container .steps .count {
    height: 15px;
    width: 15px;
    font-size: 8px;
  }

  .custom-frame-container .steps .detail {
    font-size: 8px;
    justify-content: center;
  }

  .frame-carousel p {
    font-size: 8px;
  }

  div.home-social-img {
    width: 80%;
  }

  .caption h3 {
    font-size: 24px;
    padding: 0 7%;
    font-weight: 700;
  }

  .caption .cap-name {
    font-size: 14px;
    font-weight: 500;
    color: #404040;
  }

  .bullet-cont {
    margin-bottom: 10%;
  }

  .bullet-cont:last-child {
    margin-bottom: 0%;
  }

  .bullet-cont:first-child img {
    height: 25px;
  }

  .bullet-cont:nth-child(2) img {
    height: 40px;
  }

  .bullet-cont:last-child img {
    height: 38px;
  }

  .slick-prev {
    left: 0 !important;
    z-index: 99;
  }

  .slick-next {
    right: 0 !important;
    z-index: 99;
  }

  .carousel-container .slide .carousel-control-next-icon {
    background-image: url(../../assets/arrow-left.png);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    width: 25px;
    height: 25px;
  }

  .carousel-container .slide .carousel-control-prev-icon {
    background-image: url(../../assets/arrow-left.png);
    width: 25px;
    height: 25px;
  }

  .inner {
    margin-top: 2%;
    margin-bottom: 12%;
  }

  .carousel-container {
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .frame-card img {
    height: 210px;
    width: 100%;
    object-fit: cover;
  }

  .double-sec h2.headmedium {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .img-right img {
    height: 150px;
  }

  .carousel-caption h3 {
    font-size: 1.2rem;
  }

  .headxsmall {
    margin: 15px 0;
  }

  div.double-sec a {
    font-size: 15px;
  }

  .home-social-img img {
    max-width: unset;
    height: 170px;
  }

  .img-text-container .img-text {
    padding: 0% 0;
    margin-bottom: 5%;
  }

  .frame-card {
    min-width: 70%;
  }

  .frame-cards,
  .home-social {
    flex-wrap: nowrap;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 4%;
  }

  .insta-banner .whitecolor {
    font-size: 14px;
    font-weight: 600;
  }

  .img-text {
    flex-direction: column;
  }

  .img-text-container .container.img-text,
  .img-text-container img {
    padding-right: 0;
    padding-left: 0;
  }

  .mob.insta-banner {
    left: unset;
    right: unset;
    margin: 0 20%;
  }

  .mob.insta-banner div {
    padding-left: 15px;
  }

  .desk.insta-banner {
    display: none !important;
  }

  #nhome .bullet .bullet-cont h2.head {
    font-size: 16px;
  }

  .art-love {
    font-size: 16px;
  }

  .flg-ship {
    font-size: 16px;
  }

  .lst-banner-button {
    font-size: 8px;
  }

  .frame-carousel .frame-carousel-img {
    height: 190px;
  }

  .art-carousel .art-carousel-img {
    height: 130px;
  }

  .custom-frame-container .steps .count {
    height: 15px;
    width: 15px;
    margin: 0 auto;
  }

  .custom-frame-container .steps .detail {
    font-size: 8px;
  }

  .frame-carousel p {
    font-size: 8px;
  }

  .shop-art-banner .shopart-bannerimg {
    height: 250px;
    min-height: 250px;
  }

  div.home-social-img {
    width: 80%;
  }

  .caption h3 {
    font-size: 24px;
    padding: 0 7%;
    font-weight: 700;
  }

  .caption .cap-name {
    font-size: 14px;
    font-weight: 500;
    color: #404040;
  }

  .bullet-cont {
    margin-bottom: 10%;
  }

  .slick-prev {
    left: 0 !important;
    z-index: 99;
  }

  .slick-next {
    right: 0 !important;
    z-index: 2;
  }

  .carousel-container .slide .carousel-control-next-icon {
    background-image: url(../../assets/arrow-left.png);
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    width: 25px;
    height: 25px;
  }

  .carousel-container .slide .carousel-control-prev-icon {
    background-image: url(../../assets/arrow-left.png);
    width: 25px;
    height: 25px;
  }

  .inner {
    margin-top: 2%;
    margin-bottom: 12%;
  }

  .carousel-container {
    margin-top: 20%;
    margin-bottom: 20%;
  }

  .frame-card img {
    height: 210px;
    width: 100%;
    object-fit: cover;
  }

  .double-sec h2.headmedium {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .img-right img {
    height: 150px;
  }

  .carousel-caption h3 {
    font-size: 1.2rem;
  }

  .headxsmall {
    margin: 15px 0;
  }

  div.double-sec a {
    font-size: 15px;
  }

  .home-social-img img {
    max-width: unset;
    height: 170px;
  }

  .img-text-container .img-text {
    padding: 0% 0;
    margin-bottom: 5%;
  }

  .frame-card {
    min-width: 70%;
  }

  .frame-cards,
  .home-social {
    flex-wrap: nowrap;
    overflow: auto;
    white-space: nowrap;
    margin-bottom: 4%;
  }

  .insta-banner .whitecolor {
    font-size: 14px;
    font-weight: 600;
  }

  .img-text {
    flex-direction: column;
  }

  .img-text-container .container.img-text,
  .img-text-container img {
    padding-right: 0;
    padding-left: 0;
  }

  .mob.insta-banner {
    left: unset;
    right: unset;
    margin: 0 20%;
  }

  .mob.insta-banner div {
    padding-left: 15px;
  }

  .desk.insta-banner {
    display: none !important;
  }
}

.double-sec {
  padding-left: 0px;
  padding-right: 0px;
}

.double-sec-right .img-right img {
  height: 98%;
}

.double-sec-right .img-right {
  height: 100%;
}

@media screen and (min-width: 575px) {
  .main-heading {
    font-family: "GT Super Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 140%;
  }

  .second-heading {
    font-family: "GT Super Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: #1D1F22;
  }

  .art-love-details {
    font-size: 20px;
    text-align-last: center;
  }

  .custom-frame-details {
    font-size: 20px;
  }

  .view-store-banner {
    min-height: unset !important;
  }

  .sub-heading {
    font-weight: 390;
    font-size: 20px;
    line-height: 160%;
  }

  .custom-frame-container .steps .one,
  .custom-frame-container .steps .two,
  .custom-frame-container .steps .three {
    display: flex;
    flex-direction: row;
    width: auto;
    white-space: nowrap;
  }

  .custom-frame-container .steps .two,
  .custom-frame-container .steps .three {
    padding-left: 3.8%;
  }


  .slick-arrow {
    width: 40px;
    height: 40px;
  }

  div.framedream button {
    font-size: 18px;
  }

  .img-right {
    padding-right: 0;
  }

  .frame-card-sub {
    font-size: 14px;
  }

  .color-boxes {
    margin-top: 15px;
  }

  div.framedream {
    margin-top: 0;
    padding: 0;
  }

  .double-sec-right {
    padding-left: 2%;
    height: 100%;
  }

  .home-social>.home-social-img {
    width: 20%;
    height: 100%;
  }

  .home-social-img .insta-banner img {
    height: 36px;
    width: 36px;
  }

  .home-social .home-social-img .home-social-img-img {
    width: 100%;
  }

  .double-sec-left {
    padding-right: 2%;
    padding-left: 0;
  }

  .double-sec-left .img-right img {
    padding-right: 8%;
  }

  .inner {
    margin-top: 3%;
    margin-bottom: 5%;
  }

  div.topbanner {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 4rem auto;
    padding: 0;
  }

  .framedream h2 {
    font-size: 60px;
    line-height: 130%;
  }

  div.topbannerimg img {
    height: 430px;
    width: 100%;
    object-fit: cover;
  }

  .carousel-container {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .carousel-caption h3 {
    font-weight: 900;
    font-size: 36px;
    margin-bottom: 1.3rem;
    font-family: "Noto Serif", serif;
  }

  .caption h3 {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 1.3rem;
    font-family: "Noto Serif", serif;
  }

  .bullets {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .bullet-cont {
    padding: 0 5%;
  }

  .bullet-cont .headmedium {
    padding: 0 10%;
  }

  .frame-cards-container .headnormal {
    font-size: 36px;
  }

  .frame-cards-container .headxsmall {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 5%;
    margin-top: 1.5%;
  }

  .headnormal {
    margin-bottom: 10px;
    font-size: 1.75rem;
  }

  .headxsmall {
    margin-bottom: 20px;
  }

  .frame-cards-container {
    padding-left: 0;
    padding-right: 0;
  }

  .img-text-text {
    padding: 7%;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .reverse .img-text-text {
    padding: 7% 7% 7% 0%;
  }

  .mob.insta-banner {
    display: none !important;
  }

  div.framedream button {
    font-size: 18px;
  }

  .img-right {
    padding-right: 0;
  }

  .frame-card-sub {
    font-size: 14px;
  }

  .color-boxes {
    margin-top: 15px;
  }

  div.framedream {
    margin-top: 0;
    padding: 0;
  }

  .double-sec-right {
    padding-left: 2%;
    height: 100%;
  }

  .home-social>.home-social-img {
    width: 20%;
    height: 100%;
  }

  .home-social-img .insta-banner img {
    height: 36px;
    width: 36px;
  }

  .home-social .home-social-img .home-social-img-img {
    width: 100%;
  }

  .double-sec-left {
    padding-right: 2%;
    padding-left: 0;
  }

  .double-sec-left .img-right img {
    padding-right: 8%;
  }

  .inner {
    margin-top: 3%;
    margin-bottom: 5%;
  }

  div.topbanner {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin: 4rem auto;
    padding: 0;
  }

  .framedream h2 {
    font-size: 60px;
    line-height: 130%;
  }

  div.topbannerimg img {
    height: 430px;
    width: 100%;
    object-fit: cover;
  }

  .carousel-container {
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .carousel-caption h3 {
    font-weight: 900;
    font-size: 36px;
    margin-bottom: 1.3rem;
    font-family: "Noto Serif", serif;
  }

  .caption h3 {
    font-weight: 700;
    font-size: 36px;
    margin-bottom: 1.3rem;
    font-family: "Noto Serif", serif;
  }

  .bullets {
    padding-left: 9rem !important;
    padding-right: 9rem !important;
  }

  .bullet-cont {
    padding: 0 5%;
  }

  .bullet-cont .headmedium {
    padding: 0 10%;
  }

  .frame-cards-container .headnormal {
    font-size: 36px;
  }

  .frame-cards-container .headxsmall {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 3%;
    margin-top: 1%;
  }

  .headnormal {
    margin-bottom: 10px;
    font-size: 1.75rem;
  }

  .headxsmall {
    margin-bottom: 20px;
  }

  .frame-cards-container {
    padding-left: 0;
    padding-right: 0;
    padding: 2%;
  }

  .img-text-text {
    padding: 7%;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .reverse .img-text-text {
    padding: 7% 7% 7% 0%;
  }

  .mob.insta-banner {
    display: none !important;
  }
}

.img-text-text p {
  font-family: "CircularStd", serif;
  font-size: 20px;
  margin-top: 20px;
  line-height: 130%;
  font-weight: 400;
  color: #1d1f22;
}

.img-text-container .first {
  padding-bottom: 8.5%;
}

.secondarycolor {
  color: var(--secondary);
}

.bgwhite {
  background-color: var(--white);
}

.bgsecondary {
  background-color: var(--secondary);
}

.whitecolor {
  color: var(--white);
}

.topbanner {
  margin: 20px 0;
}

/* .framedream .btns{
    padding: 0 20px;
} */

.framedream h2 {
  background-color: white;
  font-weight: 900;
  width: 85%;
  font-family: "Noto Serif", serif;
}

.home-boxes .box {
  cursor: default;
}

.btns {
  padding: 20px 0;
}

.framedream button {
  border: none;
  padding: 10px 25px;
  font-size: 12px;
}

.topbannerimg img {
  height: 250px;
  width: 78%;
}

.framedream {
  position: relative;
  z-index: 3;
  margin-top: -70px;
}

.topbannerimg {
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
}

/* Double Section */
.double-sec a {
  font-size: 21px;
}

/* BULLET */
.bullet-cont p {
  font-size: 18px;
  padding: 0 0;
  font-weight: 420;
  line-height: 130%;
}

.box.first {
  height: 20px;
  width: 20px;
}

.box {
  height: 10px;
  cursor: pointer;
  margin-right: 10px;
  width: 10px;
}

.frame-card {
  border: 1px solid #f1f2f3;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 20px;
}

.frame-cards-container .headxsmall {
  font-style: normal;
  font-weight: 390;
  font-size: 20px;
  line-height: 130%;
  /* Secondary Main */
  color: #404040;
}

/* IMGTEXT */
.grey-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-text-container img {
  padding-right: 0;
  padding-left: 0;
  object-fit: cover;
  min-height: 280px;
}

.img-text {
  padding: 5% 0;
}

/* SOCIAL */
.home-social-img {
  margin-right: 10px;
}

.home-social-img:last-child {
  margin-right: 0;
}

.home-social-img:last-child img {
  width: 100%;
}

.insta-banner {
  position: absolute;
  z-index: 2;
  left: -10px;
  right: -10px;
  top: 45%;
  padding: 15px 25px;
}

.insta-banner img {
  height: 20px;
}

.insta-banner div {
  padding-left: 20px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 130%;
}

/* CAORUSEL  */

.carousel-container .carousel-control-next-icon {
  background-image: url(../../assets/rightarrow.png);
}

.carousel-container .carousel-control-prev-icon {
  background-image: url(../../assets/leftarrow.png);
}

.carousel-container .carousel-caption {
  color: var(--secondary);
}

.carousel-container .carousel-item {
  height: 200px;
}

.caption {
  height: 100%;
}

.caption p {
  height: 100%;
  font-family: "Noto Serif", serif;
  display: flex;
  justify-content: center;
}

.carousel .ui-disabled {
  cursor: not-allowed;
}

.caption h3 {
  display: flex;
  justify-content: center;
  text-align: center;
}

.star_rate img {
  margin: 0 10px;
  height: 20px;
}

.star_rate {
  margin: 20px 0;
  display: flex;
  justify-content: center;
}

.carousel-control-prev {
  height: fit-content;
  position: absolute;
  top: 45%;
}

.carousel-control-next {
  height: fit-content;
  position: absolute;
  top: 45%;
}

/*awr*/

@media only screen and (min-width: 575px) {
  .shopart-bannerimg {
    min-height: unset;
  }
}