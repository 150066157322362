.designartaddress-container{
    width: 695px;
    /* margin-bottom: 20px; */
}

.designartaddress-container .partner-input{
    width: 220px;
}

.imgitems{
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #ACACAC;
}

.mb-20{
    margin-bottom: 20px;
}

.partner-inputmsg{
    height: 196px;
    padding-top: 14px;
}

.partner-inputmsg:focus{
    /* border: none; */
    outline: none;
}

.designart-conrtainer .partner-subtitle1{
    padding-top: 8px;
    font-size: 28px;
    line-height: 30px;
}

.designupload-text{
    font-weight: 420;
    font-size: 16px;
    line-height: 24px;
    color: #ACACAC;
    cursor: pointer;
}

.designart-note{
    color: #ACACAC;
    font-size: 14px;
    line-height: 20px;
    font-weight: 390;
    margin-top: 10px;
    margin-bottom: 40px;
}

.cursor-pointer{
    cursor: pointer;
}

.designart-citycontainer{
    width: 220px;
}

@media only screen and (max-width:992px){
    .designartaddress-container{
        width: 100%;
    }
    .designartaddress-container input:first-child{
        width: 625px;
        margin-bottom: 20px;
    }
    .designartaddress-container .partner-input{
        width: 625px;
        margin-bottom: 20px;
    }
    .designartaddress-container input:last-child{
     
        margin-left: 3%;
    }
}
@media only screen and (max-width:576px){
    .designartaddress-container{
        width: 100%;
    }
    .designartaddress-container input:first-child{
        width: 100%;
        margin-bottom: 20px;
    }
    .designartaddress-container .partner-input{
        width: 48%;
        margin-bottom: 20px;
    }
    .designartaddress-container input:last-child{
        margin-left: 3%;
    }

    .designart-citycontainer{
        width: 100%;
    }
    .designart-citycontainer .partner-input{
        width: 100%;
    }
}