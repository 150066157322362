.contactus-container .contact-sub{
    /* width: 722px;
    max-width: 722px; */
    font-size: 20px;
    line-height: 28px;
}

.contact-calling{
    width: 16px;
    height: 16px;
}

.contact-mailing{
    width: 18px;
    height: 12px;
}

.contact-locating{
    width: 12px;
    height: 16px;
}

.contactus-details{
    color: #404040;
    font-size: 12px;
    line-height: 12px;
    margin: 13px 0px;
}

.contactus-subcontainer{
    width: 90% ;
    max-width: 90% ;
    margin: auto;
    margin-bottom: 200px;
}

.contactus-subcontainer .partner-subtitle{
    padding-bottom: 20px;
}

.contactus-subcontainer a{
    margin-left: 20px;
}
.contactus-subcontainer label{
    margin-left: 20px;
}

.contactus-titlecontainer{
    padding:75px 0 60px;
}

.cursor-pointer{
    cursor: pointer;
}