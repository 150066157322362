:root {
    --blackmain: #1D1F22;
    --white: #ffffff;
}

@media screen and (max-width: 575px) {
    .desk-footer {
        display: none;
    }

    .illusion {
        display: none;
    }

    .social-mobile {
        padding: 8% 15px;
        background-color: #404040;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .social-icon-mobile img {
        margin-inline: 20px;
    }

    .mobile-footer .accordion__button[aria-expanded='true']::before,
    .accordion__button[aria-selected='true']::before {
        transform: rotate(-135deg);
        margin-top: 2%;
    }

    .follow {
        font-family: 'CircularStd', serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0px;
        color: #FFFFFF;
        margin-bottom: 5%;
    }

    .socail-icon-img {
        width: 24px;
        height: 24px;
    }

    .mobile-footer .body_links a {
        color: #43464E;
        font-weight: normal;
        padding: 10px 0;
    }

    .accord-title {
        color: #1D1F22;
        font-weight: 600;
    }
}

@media screen and (min-width:575px) and (max-width:992px) {
    .footer-2 .footer-social-cont .footer-head {
        font-size: 14px;
    }

    .footer-bottom .terms-privacy a {
        font-size: 14px;
        margin-right: 12px;
    }

    .footer-2 .desk-footer .header-container .footer-logo {
        height: 140px;
    }

    .footer-2 .desk-footer .footer-menu-list .footer-head {
        font-size: 16px;
    }

    .footer-2 .footer-menu-list .footer-subhead {
        font-size: 12px;
    }

    .footer-bottom a,
    .footer-bottom p {
        font-size: 14px;
    }

    .footer-menu-sections {
        padding-right: 8px;
    }

    .footer-menu-list {
        padding-right: 8px;
    }
}

@media screen and (min-width: 575px) {
    div.footer-social-cont.col-sm-4 {
        padding-left: 7%;
    }

    .footer-bottom img {
        height: 18px;
    }

    .footer-bottom {
        font-size: 18px;
        font-style: normal;
        font-weight: 420;
        font-size: 18px;
        line-height: 130%;
    }

    .mobile-footer {
        display: none;
    }
}

.footer-bottom {
    font-family: Circular Std;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 130%;
    display: flex;
    align-items: center;
    color: #8D8F9A;
}

.footer-menu-list {
    display: flex;
    flex-direction: column;
    font-family: Circular Std;
    padding-left: 0;
}

.termsOfService-text {
    white-space: nowrap;
}

.desk-footer .footer-head {
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    color: #1D1F22;
}

.footer-menu-list .footer-subhead {
    font-weight: 420;
    font-size: 16px;
    line-height: 130%;
    color: #43464E;
    width: 100%;
    display: block;
}

.copy-right {
    color: #8D8F9A;
}

.footer-2 .desk-footer .footer-logo {
    height: 152px;
    width: auto;
    object-fit: contain;
}


.desk-footer {
    padding-top: 3%;
    padding-bottom: 2%;
}

.desk-footer .footer-logo {
    width: 40%;
    margin-top: 1.8%;
}

.footer-menu-list h5 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 20px;
    font-family: 'CircularStd', serif;
}

.terms-privacy {
    font-family: 'CircularStd', serif;
}

.footer-social-cont .black-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    padding: 2% 2%;
}

.footer-social-cont input {
    width: 100%;
}

.footer-social-cont .footer-head {
    font-size: 18px;
}

.mob-signup .footer-head {
    font-weight: bold !important;
    font-size: 20px;
    line-height: 160%;
    color: #404040;
}

.mob-signup .black-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 16px;
    padding: 1%;
}

.mob-signup input {
    width: 99.5%;
    margin-left: 0.5%;
    padding: 1% 1%;
    border: 1px solid #000000;
}

.acc_arrow {
    position: absolute;
    height: 20px;
    right: 20px;
}

#mob_footer .card-header {
    font-weight: 900;
    border: none;
    font-size: 14px;
}

#mob_footer .card {
    border: none;
}

.body_links {
    display: flex;
    flex-direction: column;
}

.body_links a {
    color: var(--blackmain);
    padding: 5px 0;
    font-size: 14px;
}

.accordion__button:before {
    position: absolute;
    right: 15px;
}

.social-icons img {
    height: 25px;
    margin-right: 32px;
    margin-bottom: 40px;
}

.other-mob-links {
    column-gap: 5%;
    margin-top: 6%;
}

.other-mob-links a {
    font-size: 14px;
}