/* GENRAL */
:root {
  --secondary: #404040;
  --secondary-light: #8C8C8C;
  --secondary-grey: #8D8F9A;
  --white: #ffffff;
  --grey: #e8e8e8;
  --blackmain: #1D1F22;
  --white: #ffffff;
}

button {
  border: none;
}

img:focus,
select:focus {
  outline: none;
}

.secondarygrey {
  color: var(--secondary-grey);
}

.secondarycolor {
  color: var(--secondary);
}

.secondarylightcolor {
  color: var(--secondary-light);
}

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/Roboto-Regular.ttf");
  font-weight: 400;
}

.blackmain {
  color: #1D1F22;
}

#blog h2.headmedium {
  color: #333;
}

.black-btn {
  padding: 10px;
  border: 1px solid #404040;
  font-size: 0.8rem;
}

a {
  color: unset;
}

p {
  margin-bottom: 0;
}

.bgwhite {
  background-color: var(--white);
}

.bggrey {
  background-color: var(--grey);
}

.bgsecondary {
  background-color: var(--secondary);
}

.whitecolor {
  color: var(--white);
}


.container {
  width: 85% !important;
  max-width: 85% !important;
  margin: auto;
}

.header-container {
  width: 90% !important;
  max-width: 90% !important;
  margin: auto;

}

.headmedium {
  font-weight: 600;
  font-size: 22px;
}

.headnormal {
  font-weight: 600;
  font-size: 26px;
}

.headxsmall {
  font-weight: 400;
  font-size: 12px;
}


.btn-white {
  color: var(--secondary);
  border: 2px solid var(--secondary);
}

/* GENRAL END */
/* FRAME MODEL */
.overlay_gradient {
  display: block;
  margin-top: 0px;
  margin-left: 0px;
  opacity: 1;
  position: absolute;
  background: -webkit-linear-gradient(315deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0) 37%);
  background: linear-gradient(135deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.06) 20%, rgba(255, 255, 255, 0) 37%);
  z-index: 1000;
  height: 100%;
  width: 100%;
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

.three_d_box_container:hover .overlay_gradient {
  opacity: 0;
}

.front_mount {
  background-size: cover;
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px inset;
  transform: rotateY(0deg) translateZ(-3.58333px) translateY(0px) translateX(0px);
}

.right,
.right_inner {
  transform: rotateY(90deg) translateZ(14.3333px) translateY(0px) translateX(14.3333px);
}

.left,
.left_inner {
  transform: rotateY(90deg) translateZ(-14.3333px) translateY(0px) translateX(14.3333px);
}

/* .three_d_box_container:hover{
  transform: matrix3d(0.77,0,-0.64,-0.0005,0.00,1,0.00,0,0.64,0,0.77,0,0,0,0,1);
  -webkit-transform: matrix3d(0.77,0,-0.64,-0.0005,0.00,1,0.00,0,0.64,0,0.77,0,0,0,0,1);
} */
/* .three_d_box_container {
  transform: matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1)  ;
  -webkit-transform: matrix3d(1,0,0,0,0,1,0,0,0,0,1,0,0,0,0,1);
} */
a.bgsecondary:hover {
  color: white;
}

#frame-3d *,
#frame-3d::after,
#frame-3d::before {
  box-sizing: unset;
}

.frame-edit-preview-img #frame-3d:hover .three_d_box_container {
  transform: none
}

/* #frame-3d:hover .three_d_box_container{
  transform: matrix3d(0.72721,0,0.480436,-0.00065,0,1,0,0,-0.480436,0,0.72721,0,0,0.5,0.3,1);
} */
.three_d_box_container {
  transition: transform 1s ease 0s;
  opacity: 1;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  position: relative;
  margin: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transition: -webkit-transform 1s;
  transition: -webkit-transform 1s;
  transition: transform 1s;
  transition: transform 1s, -webkit-transform 1s;
  opacity: 0;
}

.three_d_box_container figure {
  position: absolute;
  z-index: 100;
  opacity: 1;
}

.three_d_face,
.three_dimensional_frame {
  outline: 1px solid transparent;
}

figure {
  margin: 0;
}

.back_float_mount_shadows {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.1), 0 2px 15px rgba(0, 0, 0, 0.2), -15px 2px 20px -2px rgba(0, 0, 0, 0.1), 0px 2px 5px -2px rgba(0, 0, 0, 0.2), 0px 0px 4px -2px rgba(0, 0, 0, 0.1);
  opacity: 0.3;
}

.three_d_box_container figure {
  position: absolute;
  z-index: 100;
  opacity: 1;
}

.three_d_face,
.three_dimensional_frame {
  outline: 1px solid transparent;
}

.back_frame_shadows {
  position: absolute;
  width: 100%;
  height: 100%;
  box-shadow: 0px 5px 7px 5px rgba(0, 0, 0, 0.13), 0px 14px 22px 12px rgba(0, 0, 0, 0.35);
  -webkit-transition: opacity 0.5s;
  transition: opacity 0.5s;
}

/* article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
display: block;
}

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section, summary {
  display: block;
}
figure {
  margin: 0;
}
figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
} */



/* FRAME MODEL END */

/* FRAME SELECT */
#frame-3d {
  width: auto;
  display: flex;
  padding: 5%;
  position: relative;
  justify-content: center;
}

.select-thumbs {
  border: 2px solid black;
  border-radius: 10px;
  height: 70px;
  width: 70px;
  margin: 10px;
}

.select-thumbs img {
  object-fit: cover;
  border-radius: 10px;
  width: 100%;
}

.frame-select {
  display: flex;
  cursor: pointer;
}

/*  */

/* SIZE INPUT */
.size-input {
  padding: 10px;
}

.size-input input {
  padding: 10px;
  border-radius: 10px;
  margin-left: 5px;
}

/*  */

#frame-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 450px;
  width: 100%;
  background-color: rgb(240, 240, 240);
}

#controls {
  padding-left: 2%;
}

.img-text-text h2.headmedium {
  font-size: 36px;
  font-family: 'Noto Serif', serif;
}

.double-sec h2.headmedium {
  font-size: 32px;
  margin-bottom: 35px;
}

h2.headmedium {
  font-family: 'Noto Serif', serif;
}

#stores .blog-img img {
  object-fit: cover;
  height: 185px;
}

#company .blog-img img {
  object-fit: cover;
  height: 185px;
}

.alert-template {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 20px 10px;
  padding-right: 50px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 14px;
  color: #ffffff;
  background-color: #404040;
  z-index: 9;
  border-radius: 4px;
  margin-top: 70px !important;
  max-width: 400px;
  min-width: 333px;
  background: #FFFFFF;
  border: 1px solid #E4E4E4;
  /* shadow */
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.alert-template p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #565656;
  margin-left: 50px;
  margin-right: 10px;
}

.alert-template h6 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* black */
  color: #141414;
  margin-left: 50px;
  margin-right: 10px;
}

.alert-template .err-icon {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 16px;
  left: 16px;
  right: 0;
  margin: auto 0;
}

.alert-template .alert-close {
  width: 16px;
  height: 15.95px;
  cursor: pointer;
  margin-left: 10px;
  position: absolute;
  top: 20px;
  right: 22px;
}

@media screen and (max-width: 575px) {
  h2.headmedium {
    margin-top: 30px;
    margin-bottom: 20px;
    padding-top: 20px;
  }

  .container {
    width: 100% !important;
    max-width: 100% !important;
  }

  .alert-template p {
    font-size: 8px;
    line-height: 10px;
  }

  .alert-template {
    font-size: 8px;
    line-height: 10px;
    min-width: 200px;
  }

  .alert-template .alert-close {
    width: 8px;
    height: 8px;
  }

  .alert-template .err-icon {
    width: 15px;
    height: 15px;
  }
}

@media screen and (min-width: 575px) {
  .mob-only {
    display: none;
  }

  .container-sm {
    width: 85% !important;
    max-width: 85% !important;
    margin: auto;
  }

  .bullets h2.headmedium,
  .checkout-main h2.headmedium {
    font-size: 26px;
  }

  h2.headmedium {
    font-size: 36px;
  }

  h1.headmedium {
    font-size: 36px;
  }

  p.sub-title {
    font-size: 17px;
    padding: 0 20%;
  }
}

@media screen and (min-width:576px) and (max-width:993px) {
  .container {
    width: 90% !important;
    max-width: 90% !important;
  }

  .frame-card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}