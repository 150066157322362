@media screen and (min-width: 575px) {
  .price_cart button {
    font-size: 18px;
  }
}

@media screen and (max-width: 575px) {
  div.input-pin input {
    width: 100%;
  }

  .main {
    min-height: auto !important;
    position: relative;
  }

  .frame-edit-carousel {
    padding: 0;
  }

  #frame-container {
    min-height: auto;
  }

  .price_cart button {
    font-size: 16px;
  }

  .frame-edit-carousel {
    height: auto;
  }

  .frame_edit_container .frame-controls {
    padding: 0;
  }

  .star-contain {
    margin-left: 0;
  }

  .thumb-cont .thumb {
    width: 24%;
    height: 70px;
    z-index: 2 !important;
  }

  div.frame-thumbs img {
    height: 80px;
  }

  div.frame-edit-carousel {
    position: relative;
    margin-bottom: 30px;
  }

  /* .see-all-frames {
    background-color: #f1f2f3;
    padding: 20px 0px 60px 20px;
    margin-bottom: -40px;
  } */
  .frame-thumbs {
    overflow-y: scroll;
  }

  .mat-head {
    flex-direction: column-reverse;
  }

  div p.final-size {
    margin-bottom: 20px;
    font-size: 12px;
    line-height: 120%;
    font-weight: normal;
    color: #404040;
  }

  p.green {
    font-weight: normal;
    font-size: 12px;
    line-height: 120%;
    color: #5ece7b;
  }
}

.padslider {
  align-items: center;
  margin-top: 20px;
  justify-content: space-between;
}

.padslider .golden-ratio {
  justify-content: flex-end;
  padding: 1% 5%;
}

.padslider input {
  margin-right: 10px;
}

#myRange {
  cursor: pointer;
}

.tabs {
  margin-bottom: 10px;
}

.select-title {
  font-size: 12px;
  line-height: 160%;
  color: #8c8c8c;
  margin-bottom: 10px;
}

.mat-head {
  justify-content: space-between;
}

.frame-thumb-container {
  text-align: center;
}

.frame_edit_container {
  margin-bottom: 50px;
  margin-top: 5%;
}

.slidecontainer {
  width: 100%;
}

.mat-head p {
  font-size: 18px;
  line-height: 130%;
  color: #1d1f22;
}

.down-arrow {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  height: 5.4px;
  width: 13.27px;
}

.drop-box {
  z-index: 9;
  /* border: 1px solid white; */
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-top: none;
  left: 0;
  /* top: 101%; */
  width: 100%;
  cursor: pointer;
}

.drop-box .brandon:hover {
  background-color: lightblue;
}

.frame-edit-preview-edit .details {
  display: none;
}

.detailed-frame .selected-2 .frame-edit-preview-edit .details {
  display: flex;
  width: 80%;
  margin: 0% auto 10% auto;
  text-align: center;
}

#frame_preview .thumb-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.slider {
  -webkit-appearance: none;
  width: 30%;
  height: 5px;
  background: #8c8c8c;
  outline: none;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.wall-placed-image {
  padding: 0;
}

.wall-placed-image #frame-container {
  min-height: 40px;
}

.wall-placed-image .frame-edit-preview-img {
  position: absolute;
  left: 0;
  right: 0;
  top: 8%;
  bottom: 0;
  margin: auto;
}

.wall-placed-image .frame-edit-preview {
  height: auto;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 25px;
  background: #6c3f2c;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  background: #6c3f2c;
  cursor: pointer;
}

.static-preview img {
  width: 90%;
  height: 400px;
  object-fit: cover;
}

.thumb.active {
  opacity: 1;
}

.thumb {
  height: 100px;
  opacity: 0.4;
  cursor: pointer;
  width: 100%;
}

.thumb img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.thumb-cont {
  margin-top: 10px;
}

.main {
  min-height: 450px;
}

.red {
  color: crimson;
  font-size: 14px;
  line-height: 130%;
  text-align: left;
}

.frame-thumbs img {
  height: 130px;
  margin-left: -20px;
}

.frame-thumbs {
  justify-content: space-between;
  margin: 30px 0;
}

button:disabled {
  background: grey;
  cursor: not-allowed;
}

.see-all-frames {
  align-items: center;
  margin-bottom: 20px;
}

.see-all-frames p {
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  color: #404040;
  border-bottom: 1.5px solid #404040;
}

.type-bottom p {
  cursor: pointer;
}

p.final-size {
  font-size: 16px;
  line-height: 130%;
  color: #8c8c8c;
}

.see-all-frames img {
  height: 15px;
  margin-right: 5px;
}

div.frame-head {
  padding: 0;
}

.frame-head h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 160%;
  display: flex;
  align-items: center;
  color: #1d1f22;
  margin-bottom: 0;
  text-transform: capitalize;
}

.frame-head p {
  font-size: 18px;
  line-height: 130%;
  color: #8c8c8c;
}

.frame-header {
  justify-content: space-between;
}

.art-type {
  margin: 20px 0;
}

.art-type .art-type-right .brown {
  cursor: default;
}

.art-type .head {
  cursor: default;
}

.art-type p {
  cursor: pointer;
  font-size: 14px;
  line-height: 130%;
  color: #8c8c8c;
  margin-right: 10px;
  margin-top: 10px;
}

p.brown {
  color: #6c3f2c;
  font-weight: bold;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.bgcolor-box {
  background: #F1F2F3;
}

.art-type-right .art-type {
  font-style: normal;
  font-weight: 390;
  font-size: 18px;
  line-height: 100%;
  color: #404040;
}

.art-type-right .art-type-bottom {
  font-weight: 330;
  font-size: 16px;
  line-height: 100%;
  color: #404040;
  cursor: pointer;
}

.drop-box {
  background-color: white;
}

.first-drop-box {
  position: absolute;
}

.thumb-cont .thumb {
  border: 0.5px solid #000000;
  z-index: 99;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.type-select {
  margin-bottom: 30px;
}

#frame_edit #footer {
  margin-top: 10%;
}

.type-select select {
  width: 100%;
  padding-bottom: 8px;
  margin-bottom: 10px;
  border: none;
  border-bottom: 1px solid #f1f2f3;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #1d1f22;
  background: url("../../assets/downarrow.png") no-repeat 100% #fff;
  -webkit-appearance: none;
}

.frame-edit-carousel {
  margin-bottom: 40px;
}

.green {
  font-weight: bold;
  font-size: 14px;
  line-height: 130%;
  color: darkgreen;
  margin-bottom: 30px;
  text-align: left;
}

.green1 {
  color: #5ece7b;
}

.input-pin {
  margin-top: 20px;
  text-align: left;
}

.frame-controls .input-pin {
  margin-top: 15%;
}

.input-pin p {
  text-align: left;
}

.input-pin input {
  padding-bottom: 8px;
  width: 80%;
  margin-bottom: 10px;
  border: none;
  border: 1px solid #404040;
  height: 45px;
  padding: 13px;
  color: #1d1f22;
}

.input-pin input.error {
  -webkit-appearance: none;
  animation: shake 0.2s ease-in-out 0s 2;
  box-shadow: 0 0 0.5em #F30C0C;
  -webkit-box-shadow: 0 0 0.5em #F30C0C;
  -moz-box-shadow: 0 0 0.5em #F30C0C;
}

@keyframes shake {
  0% {
    margin-left: 0rem;
  }

  25% {
    margin-left: 0.5rem;
  }

  75% {
    margin-left: -0.5rem;
  }

  100% {
    margin-left: 0rem;
  }
}

.shop_art .input-pin input {
  margin-bottom: 5px;
}

.input-pin input::placeholder {
  color: #6C6F71;
}

.input-pin input::-webkit-input-placeholder {
  color: #6C6F71;
}

.input-pin input::placeholder {
  color: #6C6F71;
  opacity: 1;
  /* Firefox */
}

.input-pin input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #6C6F71;
}

.input-pin input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #6C6F71;
}

.frame_edit_container .input-pin input {
  width: 100%;
}

.price_cart {
  justify-content: space-between;
  margin: 20px 0;
  align-items: center;
}

.price_cart p {
  font-size: 22px;
  line-height: 120%;
}

.sizesList {
  border: none;
  border-bottom: 1px solid #f1f2f3;
}