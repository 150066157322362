.pagination {
  display: flex;
  flex-direction: row;
  width: fit-content;
  margin: 0% auto 4% auto;
  font-weight: 390;
  font-size: 16px;
  line-height: 16px;
  border: none;
}

.page-icon {
  height: 15px;
  width: 7px;
  margin: 0 0 5px 5px;
  cursor: pointer;
}

.filter-boxes,
.filterItems-container {
  z-index: 9;
}

.page-item.active .page-link {
  background-color: white;
  border: transparent;
  color: black;
  font-weight: 900;
}

.artSearch-icon {
  cursor: pointer;
}

.pagination .dots {
  pointer-events: none;
}

.page-item .page-link {
  color: #5f5f60;
}

.page-icon.left-icon {
  transform: rotate(180deg);
  margin: 0 0 2px 5px;
}

.pagination button {
  background-color: white;
}

.pagination>li>.page-link {
  color: rgba(95, 95, 96, 1);
  border: none;
  margin: 3rem 1rem 2rem 1rem;
}

.frame-container .art-list .row {
  width: 90%;
  margin: 0 auto;
}

.frame-container .art-list .row .frame-ind img {
  height: 315px;
  margin-bottom: 20px;
}

.art-list .frame-ind {
  box-shadow: none;
  border: 1px solid #f1f2f3;
}

.disabled {
  color: rgba(196, 196, 196, 1);
}

.filterContainer {
  border-bottom: 0.5px solid #000000;
}

.filterCat .filter-head {
  font-size: 16px;
  color: #404040;
}

.filteredResults {
  font-size: 16px;
  font-weight: 390;
}

.artDown-arrow {
  width: 14px;
  height: 7px;
}

.searchFilter-input {
  color: rgba(140, 140, 140, 1);
  border: none;
  width: 90%;
}

.searchFilters {
  box-sizing: content-box;
}

.searchFilters {
  border: 1px solid rgba(140, 140, 140, 1);
}

.artSearch-icon {
  width: 15px;
  height: 15px;
}

.filterCat {
  cursor: pointer;
  display: flex;
  align-items: flex-end;
}

.filterItems-container {
  z-index: 1;
  top: 100%;
}

.p-relative {
  position: relative;
}

.water-mark {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0.18;
  width: 25%;
  z-index: 9;
}

.shop_art .three_d_box_container {
  box-shadow: none;
}

.shop_art .no-frame .zoom-button-container {
  display: flex;
}

.filterItems-container .theme-container .colors {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: "touch";
  padding: 5% 15%;
  width: 130px;
  max-height: 400px;
}

.filterItems-container .theme-container .colors::-webkit-scrollbar {
  display: inherit;
}

.filterItems-container .theme-container .colors:hover::-webkit-scrollbar {
  width: 10px;
}

.filterItems-container .theme-container .colors:hover::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  -webkit-border-radius: 8px;
}

.filterItems-container .theme-container .colors:hover::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(100, 100, 100, 0.8);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

.colors {
  /* background-image: url("/assets/imagesA/artFilter.png");
  background-size: cover;
  background-position: right right; */
  background-color: #f5f5f5;
  padding-left: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.tri {
  color: #f5f5f5;
  position: relative;
  top: 7px;
  left: 2px;
}

.thot-container {
  width: 11rem;
  position: relative;
  bottom: 12px;
}

.activeFilter-close {
  width: 7px;
  height: 7px;
  cursor: pointer;
}

.activeFilter-box {
  background-color: rgba(245, 245, 245, 0.8);
  color: rgba(64, 64, 64, 1);
  font-size: 14px;
  line-height: 14px;
}

.clear-active {
  color: rgba(95, 95, 96, 1);
  font-weight: 390;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
}

.artPop-conatiner {
  z-index: 101;
  background-color: #ffffff;
  /* background-color: #bb1010; */
  /* top: 25%; */
  top: 7vh;
  left: 0;
  right: 0;
  margin: auto;
  max-height: 90vh;
  border: 1px solid #8c8c8c;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.modal-close {
  width: 14px;
  height: 14px;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.GT {
  font-family: "GT Super Ds Trial" !important;
}

.artPop-title {
  font-weight: 700;
  font-size: 36px;
  line-height: 50px;
}

.artPop-button {
  background-color: rgba(64, 64, 64, 1);
  color: white;
  font-weight: 420;
  font-size: 16px;
  line-height: 21px;
  /* height: 3.5rem; */
}

.art-list .artist-name {
  font-size: 16px;
  color: #1d1f22;
}

.art-list .name-price .name {
  font-size: 20px;
}

.artDown-arrow.active {
  transform: rotate(180deg);
}

.artPop-footer {
  font-weight: 390;
  font-size: 17px;
  line-height: 17px;
}

.paper-cont .paper-type {
  width: 48%;
}

.shop_art .paper-type img {
  max-height: unset;
}

.shop_art .paper-cont .paper-type:last-child {
  margin-right: 0;
}

.shop_art .paper-cont .paper-type {
  width: 50%;
}

.paper-cont .paper-type:last-child {
  margin-right: 4%;
}

@media only screen and (max-width: 575px) {
  .filteredResults {
    font-size: 9px;
  }

  .searchFilters {
    width: 100px;
    height: 18px;
  }

  .choose-frame-button {
    font-size: 8px;
  }

  .shop_art .shop-art-button {
    margin-left: auto;
    justify-content: flex-end;
    margin-right: 0 !important;
  }

  .searchFilters .searchFilter-input {
    height: 18px;
    font-size: 8px;
  }

  .searchFilters .artSearch-icon {
    width: 8px;
    height: 8px;
  }

  .page-icon {
    height: 10px;
    width: 5px;
    margin: 0 0 2px 1px;
  }

  .pagination>li>.page-link {
    font-size: 10px;
  }

  .pagination {
    font-size: 12px;
    line-height: 12px;
  }

  .page-icon.left-icon {
    margin: 0 0 3px 1px;
  }

  .frame-container .art-list .row {
    width: 100%;
  }

  .frame-container .art-list .row .frame-ind img {
    height: auto;
    max-height: 165px;
    vertical-align: bottom;
  }

  .art-list .frame-ind {
    flex: 1;
    margin-right: 4%;
    width: 48%;
    min-width: 48%;
  }

  .art-list .frame-ind:nth-child(2n) {
    margin-right: 0;
  }

  .activeFilter-container {
    margin-bottom: 15px;
  }

  .shop-art-button {
    width: fit-content;
    font-size: 8px;
  }

  .print-check .button {
    width: 60%;
  }

  .print-check .frame-price {
    font-size: 20px;
  }

  .shop-art-button:first-child {
    margin-right: 4%;
  }

  .thot-container {
    width: 9rem;
  }

  .artPop-button {
    font-size: 9px;
    line-height: 16px;
  }

  .artPop-footer {
    font-size: 7px;
    line-height: 7px;
  }

  .artPop-title {
    font-size: 16px;
    line-height: 22px;
  }

  .modal-close {
    width: 5px;
    height: 5px;
    top: 0.5rem;
    right: 0.5rem;
  }

  .artPop-conatiner {
    top: 30vh;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border: 0.3px solid #8c8c8c;
    left: 0;
    right: 0;
    width: 90%;
    margin: auto;
  }

  .pagination>li>.page-link {
    margin: 0 0.25rem 1rem 0.25rem;
  }

  .name {
    font-size: 0.75rem;
  }
}

@media only screen and (max-width: 320px) {
  .artPop-button {
    font-size: 7px;
  }
}

/*awr*/

.bordering {
  box-shadow: none;
  border: 1px solid #e3e2e0;
}

.border-cancel {
  border: none !important;
}

@media only screen and (max-width: 576px) {
  .custName {
    font-size: 14px !important;
    white-space: nowrap;
    width: 83px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .image-hi {
    height: 120px !important;
    max-height: 165px;
    /* min-height: 52px; */
    vertical-align: bottom;
  }

  .custArtist {
    font-size: 10px !important;
  }
}