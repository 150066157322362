#upload_select .type_icon {
    margin-right: 0;
    width: 50%;
}


#upload_select .type_icon img {
    height: auto;
    cursor: pointer;
}

.image-upload>input {
    display: none;
    cursor: pointer;
}

.image-upload {
    cursor: pointer;
}

.add-cart-button {
    font-size: 16px !important;
    width: 150px;
    min-width: unset;
    background-color: #404040;
    color: white;
    cursor: pointer;
    text-align: center;
    padding: 0.8% 4%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 575px) {
    #upload_select .type_icon {
        padding: 5px 25px;
    }

    .add-cart-button {
        font-size: 16px !important;
        width: 152px;
        min-width: unset;
        background-color: #404040;
        color: white;
        cursor: pointer;
        text-align: center;
        padding: 0.8% 4%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .grey-button {
        background-color: #404040;
        color: white;
        font-size: 20px;
        cursor: pointer;
        text-align: center;
        padding: 0.8% 4%;
        width: auto;
        min-width: 196px;
    }

    #upload_select .type_icon img {
        height: 120px;
    }

    .type_icon .tap-upload {
        font-size: 20px;
    }

    .cropBack {
        font-size: 16px;
    }

}

@media screen and (max-width: 575px) {
    #upload_select .type_icon img {
        height: 80px;
    }

    .type_icon .tap-upload {
        font-size: 16px;
    }

    .cropBack {
        font-size: 8px;
    }

    .grey-button {
        background-color: #404040;
        color: white;
        font-size: 12px;
        text-align: center;
        cursor: pointer;
        padding: 2% 8%;
        width: auto;
    }
}