.shopArt-typeSelect .art-preview-typeSelect img {
    object-fit: contain;
}

.h-auto {
    height: auto;
}

.shopArt-typeSelect .type-select-box {
    gap: 24px;
}

.shopArt-typeSelect .type-select-single-box,
.shopArt-typeSelect .paper-type {
    height: 48px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #B8B8B8;
}

.shopArt-typeSelect .type-select-single-box p,
.shopArt-typeSelect .paper-type p {
    font-size: 18px;
    line-height: 150%;
}

.shopArt-typeSelect .type-select-single-box.active,
.shopArt-typeSelect .paper-type.active {
    border: 2px solid #404040;
    background-color: #404040;
    color: #FFFFFF;
}

.shopArt-typeSelect .sub-title {
    font-size: 16px;
    line-height: 24px;
    color: var(--blackmain);
    text-align: left;
    padding-left: 15px;
    padding-right: 0;
}

.shopArt-typeSelect .paper-type img {
    width: 100%;
}

.shopArt-typeSelect .image-preview-container {
    position: sticky;
    top: 0;
}

.shopArt-typeSelect .no-zoom .zoom-button-container {
    display: none;
}

.shopArt-typeSelect .frame-select-container {
    gap: 2%;
}

.shopArt-typeSelect .size-tab {
    border: 0.5px solid #404040;
    margin-bottom: 12px;
    height: 36px;
    font-size: 16px;
    line-height: 24px;
}

.shopArt-typeSelect .size-tab.selected {
    background-color: transparent;
    color: #FFFFFF;
    background-color: #404040;
    border: 2px solid #404040;
}

.shopArt-typeSelect .frame-select-box-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    aspect-ratio: 1;
    width: 15%;
    border-radius: 50%;
    gap: 2%;
    border: 1px solid #B8B8B8;
    margin-bottom: 3%;
    cursor: pointer;
}

.shopArt-typeSelect .frame-select-box-container.active {
    border: 2px solid #404040;
}

.shopArt-typeSelect .frame-select-box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.shopArt-typeSelect .frame-select-box-container .frame-thumbnail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.frame-hover-box-arrow {
    width: 10px;
    height: 6px;
}

.frame-hover-box {
    position: absolute;
    z-index: 9;
    bottom: 0px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;

    /* identical to box height, or 100% */
    /* Dark/Normal */
}

.frame-hover-box .text {
    font-style: normal;
    font-weight: 390;
    font-size: 12px;
    line-height: 10px;
    text-align: center;
    letter-spacing: 0.2px;
    color: white;
    height: 23px;
    padding: 6px 12px;
    background-color: black;
    border-radius: 4px;
    width: max-content;
}

.shopArt-typeSelect .frame-select-box.active {
    border: 2px solid #404040;
}

.continue-arrow {
    height: 10.285714149475098px;
    width: 13.714285850524902px;
    margin-left: 6%;
}

.type-select-box img {
    aspect-ratio: 1.63;
    padding: 10px;
    object-fit: cover;
}

.shopArt-typeSelect .paper-type img {
    aspect-ratio: 1.63;
    object-fit: cover;
    padding: 10px;
}

.shopArt-typeSelect .choose-frame-button {
    width: 157px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

.shopArt-typeSelect .add-cart-arrow {
    height: 10.29px;
    width: 13.71px;

}

.shopArt-typeSelect .add-cart-button {
    display: flex;
    align-items: center;
    gap: 11px;
    height: 48px;
    padding: 0 14px;
    width: 157px;
}

.shopArt-typeSelect .second-heading {
    margin-bottom: 6px;
}

.shopArt-typeSelect .sizeTitle {
    font-size: 16px;
    line-height: 24px;
    color: var(--blackmain);
    font-weight: 500;
}

@media screen and (min-width: 575px) {
    .shopArt-typeSelect .sizeTitle {
        margin-left: 0;
    }

    .shopArt-typeSelect .frame-edit-preview-container .show-img {
        height: 50vh;
        object-fit: contain;
    }

    .shopArt-typeSelect .second-heading {
        margin-bottom: 12px;
    }

    .shopArt-typeSelect .sub-title {
        margin-bottom: 12px;
    }

    .typeSelect-section {
        margin-bottom: 40px;
    }

    .shopArt-typeSelect .frame-price.add-price {
        font-family: 'Brandon Grotesque';
        font-style: normal;
        font-weight: 600;
        font-size: 28px;
        line-height: 120%;
        /* Dark Main */
        color: #1D1F22;
    }
}


@media screen and (max-width: 575px) {
    .shopArt-typeSelect .brandon {
        font-style: normal;
        font-weight: 390;
        font-size: 14px;
        line-height: 18px;
    }

    .shopArt-typeSelect .second-heading {
        margin-bottom: 10px;
    }

    .shopArt-typeSelect .sub-title {
        margin-bottom: 8px;
    }

    .typeSelect-section {
        margin-bottom: 20px;
    }

    .shopArt-typeSelect .sizeTitle {
        font-weight: 600;
    }

    .shopArt-typeSelect .image-preview-container {
        padding-bottom: 20px;
    }

    .shopArt-typeSelect #frame-container {
        background-color: transparent;
    }

    .shopArt-typeSelect .type-select-single-box p,
    .shopArt-typeSelect .paper-type p {
        font-size: 16px;
    }

    .shopArt-typeSelect .size-tab {
        border-radius: 0px;
        margin-bottom: 10.5px;
    }

    .frame-hover-box .text {
        height: 100%;
    }

    .shopArt-typeSelect .complete_flow-details {
        padding-left: 0;
        padding-right: 0;
    }

    .shopArt-typeSelect .choose-frame-button {
        width: 120px;
        height: 36px;
    }

    .shopArt-typeSelect .frame-edit-preview-container {
        height: auto;
        min-height: 300px;
        background-color: #a7a6a2;
    }

    .shopArt-typeSelect .frame-edit-preview-container img {
        height: auto;
        max-height: 100%;
        object-fit: contain;
    }

    .shopArt-typeSelect .frame-edit-preview-container .show-img {
        max-height: 400px;
    }

    .shopArt-typeSelect .size-tab {
        padding: 0;
        height: 32px;
        font-size: 14px;
        line-height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .shopArt-typeSelect .frame-price.add-price {
        font-family: 'Brandon Grotesque';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        /* Dark Main */
        color: #1D1F22;
    }

    .shopArt-typeSelect .shop-art-button.add-cart-button {
        height: 48px;
        margin-right: 0;
    }

    .shopArt-typeSelect .art-preview-typeSelect {
        height: 300px;
        display: flex;
        align-items: center;
    }

    .zoompopup-container #frame-container {
        min-height: unset !important;
        padding: 0;
    }
}

@media screen and (min-width:576px) and (max-width:993px) {
    .shopArt-typeSelect .frame-price.add-price {
        font-size: 24px;
    }

    .shopArt-typeSelect .size-tab {
        font-size: 12px;
    }

    .shopArt-typeSelect .type-select-box {
        column-gap: 16px;
    }
}

/* .zoompopup-container {
    padding: 10%;
    background-color: rgb(240, 240, 240);
    border-radius: 8px;
    box-shadow:  0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
}

.zoompopup-container .zoom-close {
    position: absolute;
    top: 3%;
    right: 3%;
} */

.frame-edit-zoom.zoompopup-container {
    width: 100%;
}

.frame-edit-zoom.zoompopup-container #frame-container {
    min-height: unset;
    width: 100%;
}

.detailed-frame .selected-2 .frame-edit-preview-edit .zoom-button-container {
    display: flex;
}

.zoompopup-container #frame-container {
    min-height: unset;
    background-color: transparent;
}

.zoom-button-container {
    display: none;
}

.shopArt-typeSelect .zoom-button-container {
    display: flex;
}

.shopArt-typeSelect .cart-item .zoom-button-container,
.shopArt-typeSelect .App .zoom-button-container {
    display: none;
}

@media only screen and (max-width:575px) {
    .zoompopup-overlay {
        background: #a7a6a2;
    }

    .three_d_box_container {
        box-shadow: rgb(41 51 68 / 53%) 3px 5px 8px 0px;
    }

    .shopArt-typeSelect #frame-container {
        padding: 1%;
    }

    .shopArt-typeSelect .zoom-button-container {
        bottom: 2.5%;
        right: 2.5%;
    }
}