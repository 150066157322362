.view-allart-banner {
    width: 100%;
    position: relative;
}

.view-allart-banner-img-container {
    width: 100%;
    padding-top: 33.07%;
    position: relative;
    content: "";
    display: block;
}

.view-allart-banner img {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.shopart-banner {
    position: relative;
    padding-top: 79.36%;
    width: 100%;
}

.shopart-heading {
    font-size: 36px;
    font-weight: 500;
    line-height: 54px;
    letter-spacing: 0px;
    text-align: center;
}

.view-all_btn {
    font-size: 16px;
    font-weight: 390;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;

}

.light-grey-bg {
    background: #F9F9F9;
}

.shop_art .banner-button {
    font-size: 24px;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
    padding: 24px 48px;
    border-radius: 0;
    text-transform: capitalize;
}

.shopart-banner img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.view-allart-banner .button {
    bottom: 0;
    top: 0;
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
}

.popular-art-container {
    margin-top: 90px;
    margin-bottom: 90px;
}

.popular-img {}

.banner-2 {
    background: center/cover no-repeat;
    aspect-ratio: 2.52;
}

.slider-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    font-weight: 700;
    margin-top: 24px;
    color: #404040;
}

.styles-slider img {
    width: 100%;
    aspect-ratio: 0.7583;
}

.space-slider-container .slick-list {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
}

.space-slider img {
    width: 100%;
    aspect-ratio: 1;
}

.trending-art-container {
    background-color: #000000;
    padding-top: 60px;
    padding-bottom: 100px;
}

.white-text {
    color: #FFFFFF;
}

.grid-image-container {
    aspect-ratio: 1.8;
    width: 100%;
}

.grid-image-container .grid-images {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.grid-image-container .grid-images:first-child .first-image {
    aspect-ratio: 1.375;
    width: 100%;
}

.grid-image-container .grid-images:first-child .second-image {
    aspect-ratio: 1.0625;
    width: 100%;
}

.grid-image-container .grid-images:nth-child(2) .first-image {
    aspect-ratio: 0.97395833;
    width: 100%;
}

.grid-image-container .grid-images:nth-child(2) .second-image {
    aspect-ratio: 1.55833;
    width: 100%;
}

.grid-image-container .grid-images:last-child .first-image {
    aspect-ratio: 1.6696;
    width: 100%;
}

.grid-image-container .grid-images:last-child .second-image {
    aspect-ratio: 0.935;
    width: 100%;
}

.grid-image-container .grid-images img {
    object-fit: cover;
}

.trending-art-container .banner-button:hover {
    color: white;
    border: 1px solid white;
}

.art-list .frame-ind {
    position: relative;
}

.add-favourite {
    position: absolute;
    right: 18px;
    top: 18px;
    height: 38px;
    display: flex;
    gap: 8px;
    z-index: 9;
}

.frame-container .art-list .frame-ind .add-favourite img {
    background-color: #C4C4C4;
    border-radius: 50%;
    padding: 11px;
}

.frame-container .art-list .frame-ind .add-favourite .love {
    width: 38px;
    height: 38px;
}

.frame-container .art-list .frame-ind .add-favourite .plus {
    width: 38px;
    height: 38px;
}

.goto-container {
    margin-bottom: 4%;
}

.goto-container button {
    height: 28px;
    width: 58px;
    margin-left: 6px;
}

.page_input {
    width: 55px;
    height: 28px;
    margin-left: 13px;
    border: 0.5px solid #5F5F60;
}

.goto-container .d-flex {
    margin: 3rem 0 2rem 0;
}

@media screen and (max-width:575px) {
    .popular-art-container {
        margin-top: 45px;
        margin-bottom: 45px;
    }

    .trending-art-container {
        background-color: #000000;
        padding-top: 30px;
        padding-bottom: 50px;
    }

    .goto-container .d-flex {
        margin: 1rem 0 1rem 0;
    }

    .pagination>li>.page-link.arrow {
        margin: 0;
        margin-bottom: 1rem;
        padding: 4.5px 0;
    }
}

@media screen and (max-width:390px) {
    .pagination>li>.page-link {
        margin: 0 0.15rem 1rem 0.15rem;
        font-size: 10px;
    }
}

.shop_art .slick-arrow.slick-disabled {
    display: block !important;
}

.banner-3 {
    background: center/cover no-repeat;
    aspect-ratio: 2.428;
}

.bullet .head {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;

}

.bullet .brandon {
    font-size: 16px;
    font-weight: 390;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
}

.bullet img {
    max-height: 90%;
    vertical-align: bottom;
    display: table-cell;
    text-align: center;
}

.shop_art .gift-heading {
    background-color: #F9F9F9;
}

.art-blog .title {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0px;
    text-align: center;
    color: #1D1F22;
}

.art-blog .desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    max-height: 72px;
    min-height: 72px;
    font-size: 16px;
    font-weight: 390;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #404040;
}

.art-blog img {
    width: 100%;
    aspect-ratio: 1.36;
    object-fit: cover;
}

.term-collection img,
.term-artist img {
    width: 100%;
    aspect-ratio: 0.7709;
}

.text-decoration-underline {
    text-decoration: underline;
}

.gap-t {
    margin-top: 96px;
}

.gap-b {
    margin-bottom: 96px;
}

.gap-y {
    margin-top: 96px;
    margin-bottom: 96px;
}

.pad-y {
    padding-top: 72px;
    padding-bottom: 72px;
}

.artSearch-close {
    width: 20px;
    height: 20px;
    cursor: pointer;
    margin-right: 2px;
}

@media screen and (max-width: 575px) {
    .shopart-heading {
        font-size: 24px;
        font-weight: 700;
        line-height: 36px;
        letter-spacing: 0px;
        text-align: center;

    }

    .slider-title {
        font-size: 12px;
        font-weight: 500;
        line-height: 18px;
        letter-spacing: 0px;
    }


    .space-slider-container .slick-list {
        margin-left: 0;
        margin-right: 0;
    }

    .shop_art .banner-button {
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0px;
        text-align: center;
        padding: 12px 24px;
    }

    .banner-2 {
        aspect-ratio: 0.9375;
    }

    .banner-3 {
        aspect-ratio: 1.47;
    }

    .banner-3-container {
        padding: 0;
    }

    .gap-t {
        margin-top: 48px;
    }

    .gap-b {
        margin-bottom: 48px;
    }

    .gap-y {
        margin-top: 48px;
        margin-bottom: 48px;
    }

    .pad-y {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    .art-blog-list .art-blog:last-child {
        margin-bottom: 0 !important;
    }

    .shopart-bullet .bullet:last-child {
        margin-bottom: 0 !important;
    }
}