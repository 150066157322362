.faq .accordion {
  background: #ffffff;

  /* Cards/Short Default */
  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
  border-radius: 4px;
}

.about-details a,
.articledetails a {
  text-decoration: underline;
}

.faq .accordion .button.active {
  background: #404040;
  border-radius: 4px;
  height: 40px;
  width: 40px;
}

.faq .accordion .button.active img {
  height: 2px;
  width: 16px;
}

.faq .accordion .button img {
  height: 16px;
  width: 16px;
}

.faq .accordion .button {
  background: #f8f8f8;
  border-radius: 4px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.faq .question {
  cursor: pointer;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* identical to box height, or 127% */
  align-items: center;
  color: #131718;
}

.faq .answer {
  font-style: normal;
  font-weight: 390;
  font-size: 18px;
  line-height: 30px;
  width: 88%;
  /* or 167% */

  color: #404040;
}

/* About Page */

.aboutpage .about-details {
  font-size: 20px;
  line-height: 30px;
}

@media screen and (max-width: 575px) {
  .aboutpage .about-details {
    font-size: 12px;
    line-height: 16px;
  }

  .faq .question {
    font-size: 16px;
    line-height: 18px;
  }

  .faq .answer {
    font-size: 12px;
    line-height: 14px;
  }

  .faq .accordion .button {
    width: 24px;
    height: 24px;
  }

  .faq .accordion .button img {
    width: 12px;
    height: 12px;
  }

  .faq .accordion .button.active {
    width: 24px;
    height: 24px;
  }

  .faq .accordion .button.active img {
    width: 12px;
    height: 1.5px;
  }
}

/*awr*/

.align-center-remover {
  align-items: flex-start !important;
  padding: 0 0% !important;
}

.contact-cards {
  /* border: 2px solid red; */
  -webkit-box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
  -moz-box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);
  box-shadow: 0px 5px 16px rgba(8, 15, 52, 0.06);

  width: 30%;
  margin: 2% 1.6%;
  padding: 4% 30px;
  text-decoration: none;
}

.contact-cards:hover .contact-card-title,
.contact-cards:hover p,
.contact-cards:hover label {
  text-decoration: none;
}

.contact-card-title {
  font-size: 28px;
  line-height: 28px;
  color: #131718;
}

.lonely-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-add {
  font-size: 20px;
  color: #404040;
}

@media only screen and (max-width: 576px) {
  .contact-cards {
    width: 90%;
    margin: 5% 0;
    padding: 11% 0;
  }

  .email-popup {
    min-width: 80vw !important;
  }

  .selectBack {
    margin-top: 20px;
  }

  .contact-card-title {
    font-size: 16px;
  }

  .contact-cards p {
    font-size: 12px;
  }

  .contact-cards label {
    font-size: 12px;
  }

  .about-main-details {
    text-align-last: center;
  }
}

.selectBack {
  cursor: pointer;
}

/* contact page */

.email-popup {
  position: relative;
  background: #FFFFFF;
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 8%;
  min-width: 400px;
}

.email-popup input,
.email-popup textarea,
.email-popup select {
  border: 1px solid #D1D1D1;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #ffffff;
}

.email-popup input,
.email-popup select {
  height: 51px;
}

.email-popup textarea {
  height: 106px;
}

.email-popup select {
  background-color: white;
  border: thin solid grey;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.email-popup select {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iOSIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDkgMTYiPjxwYXRoIGZpbGw9IiM1ZDViNjYiIGQ9Ik05LjE0MyAxMS40MjlsLTQuNTcxIDQuNTcxLTQuNTcxLTQuNTcxaDkuMTQzeiI+PC9wYXRoPjxwYXRoIGZpbGw9IiM1ZDViNjYiIGQ9Ik05LjE0MyA0LjU3MWwtNC41NzEtNC41NzEtNC41NzEgNC41NzFoOS4xNDN6Ij48L3BhdGg+PC9zdmc+);
  background-position: right 12px center;
  background-repeat: no-repeat;
  background-size: 7px;
}

.email-popup option {
  color: #54595E;
}

.email-popup input::placeholder,
.email-popup textarea::placeholder {
  color: #acacac;
}



.email-popup textarea {
  resize: none;
}

.email-popup .pop-head {
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  /* Secondary/Dark Color */
  color: #54595E;
}

.email-popup .submit-btn {
  background: #4F4F4F;
  border-radius: 8px;
  font-weight: 420;
  font-size: 16px;
  line-height: 23px;
  /* identical to box height */
  display: flex;
  align-items: center;
  justify-content: center;
  /* Grey / Light */
  color: #F5F5F5;
  padding: 3% 0;
  cursor: pointer;
}

.email-popup .contact-close {
  width: 24px;
  height: 24px;
  float: right;
  position: absolute;
  right: 4%;
  top: 4%;
  cursor: pointer;
}