.checkcrop {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid rgba(33, 33, 33, 0.2);
    margin-right: 15px;
}

.checkcrop:checked+label::after {
    background-color: #404040;
}

.checkcrop-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 575px) {
    .checkcrop-container {
        font-size: 8px;
    }

    .checkcrop {
        width: 18px;
        height: 18px;
        margin-right: 10px;

    }
}