#checkout .load-btns {
    justify-content: center;
    margin-top: 38px;
    border-bottom: none;
}

#checkout .load-btns .btn-load {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 130%;
    color: #1D1F22;
    padding: 0;
    width: 240px;
    border-bottom: none;
}

#checkout .cart-items-container .cart-item {
    position: relative;
}

#checkout .cart-items-container .cart-item .remove-container {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 350px;
    max-width: 70%;
    background-color: white;
    padding: 5%;
    border: 1px solid var(--secondary);
}

#checkout .cart-item .remove-container .btns {
    display: flex;
    justify-content: space-between;
    padding: 2% 3% 0 3%;
    width: 100%;
}

#checkout .profile-input::-webkit-input-placeholder {
    /* Edge */
    text-transform: capitalize;
}

#checkout .profile-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    text-transform: capitalize;
}

#checkout .profile-input::placeholder {
    text-transform: capitalize;
}

.cart-remove-button {
    margin-right: 34px;
}

.checkout-main .checkout-container.checkout-container-payment {
    width: 100%;
}

#checkout .load-btns .btn-load .line {
    width: 120px;
    border-bottom: 2px solid #F1F2F3;
    margin-bottom: -2px;
}

#checkout .load-btns .btn-load .line.active {
    border-bottom: 2px solid #1D1F22;
}

.checkout-payment .spinner3 {
    width: 32px;
    height: 32px;
    min-width: 32px;
    position: relative;
    margin-left: 16px;
}

.checkout-payment .spinner3 .loader {
    width: 32px;
    height: 32px;
    min-width: 32px;
    position: absolute;
}

.checkout-payment .spinner3 .loader .loader-parent {
    width: 32px;
    height: 32px;
    min-width: 32px;
}

.checkout-payment .spinner3 .loader .loader-parent .circle {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 3px solid #79797950;
    border-top: 3px solid #797979;
    animation: spinner3 800ms linear infinite;
}

.checkout-main .checkout-container.checkout-container-confirm {
    width: 100%;
}

.checkout-main .checkout-container-confirm .checkout-sec {
    width: 100%;
}

.checkout-main .checkout-container-confirm .checkout-sec .review_accordion {
    width: 56%;
}

#checkout-confirm .checkout-review .cart-main:first-child .cart-items-container {
    margin-top: 0px;
}

.checkout-main .pricetable {
    padding: 27px 28px;
    background-color: #F1F2F3;
}

.checkout-main .confirm-order .pricetable .table-list h6,
.checkout-main .confirm-order .pricetable .table-list p {
    font-size: 16px;
    line-height: 130%;
}


.checkout-main .confirm-order .pricetable .table-list p {
    color: var(--secondary)
}

.checkout-main .confirm-order .pricetable .table-list h6 {
    margin-bottom: 6px;
    color: var(--blackmain);
}

.checkout-main .confirm-order .pricetable .contact-us .row {
    gap: 16px;
}

.checkout-main .confirm-order .pricetable .contact-us .row .rev-stats {
    margin: 0;
}

.checkout-side-head {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
}

.checkout-heading {
    font-size: 32px;
    line-height: 42px;
    /* Dark Main */
    color: #1D1F22;
}

.checkout-ordered-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 120%;
}

.checkout-sub-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    /* Dark Main */
    color: #1D1F22;
}

.ordered-banner p {
    font-weight: 390;
    font-size: 14px;
    line-height: 130%;
}

.checkout-main .billing-type .tick,
.editaddress-pop .tick {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #979797;
    margin-right: 10px;
}

.checkout-main .billing-type {
    gap: 80px;
}

.edit-heading {
    font-size: 24px;
    line-height: 150%;
    margin-bottom: 11px;
}

.editaddress-pop .billing-type {
    gap: 20px;
}

.editaddress-pop .close-btn {
    width: 15px;
    height: 15px;
}

.checkout-main label,
.editaddress-pop label {
    font-weight: 420;
    font-size: 16px;
    line-height: 23px;
    color: #000000;
    margin-bottom: 0px;
}

.load-file-overlay {
    background-color: #00000050;
    overflow-y: auto;
}

.editaddress-pop label {
    margin-bottom: 6px;
}

.checkout-main .fields-sec .profile-input,
.editaddress-pop .fields-sec .profile-input {
    height: 48px;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #979797;
    font-weight: 390;
    font-size: 16px;
    line-height: 23px;
    margin-bottom: 24px;
    padding: 12px 20px;
}

.editaddress-pop .fields-sec .profile-input {
    margin-bottom: 12px;
}

#checkout .fields-sec input::-webkit-input-placeholder {
    /* Edge */
    font-size: 16px;
    line-height: 23px;
    color: #ADA7A7;
    font-family: "Brandon Grotesque";
}

#checkout .fields-sec input::-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    font-size: 16px;
    line-height: 23px;
    color: #ADA7A7;
    font-family: "Brandon Grotesque";
}

#checkout .fields-sec input::placeholder {
    font-size: 16px;
    line-height: 23px;
    color: #ADA7A7;
    font-family: "Brandon Grotesque";
}

.checkout-main .fields-sec .form-select {
    border-bottom: 1px solid #979797;
    font-weight: 390;
    font-size: 16px;
    line-height: 23px;
    padding: 12px 20px;
    margin-bottom: 24px;
    background: url(/assets/checkout/downarrow.png) no-repeat center right 18px white;
    background-size: 12px 4.18px;
    background-position: center right 18px;
    -webkit-appearance: none;
    appearance: none;
}

.checkout-main .fields-sec .form-select option {
    font-family: "Brandon Grotesque";
    font-size: 16px;
    line-height: 23px;
}

.checkout-main .side-desk .black-btn,
.empty-button {
    font-weight: 420;
    font-size: 18px;
    line-height: 27px;
}

#checkout .black-btn.empty-button {
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
}

.checkout-main .billing-type .tick.active,
.editaddress-pop .tick.active {
    background-color: #000000;
    border: 1px solid #000000;

}

.checkout-main .billing-type .tick.active img,
.editaddress-pop .tick.active img {
    display: block;
    width: 10px;
    height: auto;
}

.checkout-main .billing-type .tick img,
.editaddress-pop .tick img {
    display: none;
}

.checkout-main .checkout-container {
    width: 56%;
}

.checkout-main .side-desk {
    width: 40%;
}

.checkout-main .cart-items-container {
    background: #FFFFFF;
    border: 0.5px solid #979797;
    aspect-ratio: 2.97;
}

.checkout-main .checkout-cart-item-img .frame-edit-preview-edit {
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkout-main .checkout-cart-item-img #frame-container {
    min-height: unset;
    background-color: white
}

.checkout-main .checkout-cart-item-img #frame-3d {
    padding: 0;
}

.checkout-main .cart-items-container .cart-item {
    display: flex;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 20px;
    aspect-ratio: 2.97;
}

.checkout-main .cart-items-container .checkout-cart-item-img {
    width: 27.5%;
    aspect-ratio: 1;
}

.checkout-main .cart-items-container .checkout-cart-item-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.checkout-main .cart-item-titleprice .cart-item-title {
    font-size: 20px;
    line-height: 24px;
    color: #1D1F22;
}

.checkout-main .cart-item-titleprice .cart-item-description {
    font-size: 14px;
    line-height: 21px;
    /* Dark Main */
    color: #1D1F22;
}

.checkout-main .address-block {
    background: #FFFFFF;
    border: 1px solid #979797;
    padding: 17px;
    margin-bottom: 24px;
}

.checkout-main .address-block.active {
    border-width: 2px;
}

.checkout-main .address_add-button {
    gap: 13px
}

.editaddress-pop {
    width: 90%;
    max-width: 500px;
    margin: 20% auto;
    background: #FFFFFF;
    border-radius: 4px;
    padding: 24px;
}

.checkout-main .address_add-button img {
    width: 14px;
    height: 14px;
}

.checkout-main .address-block h6 {
    font-weight: 420;
    font-size: 20px;
    line-height: 30px;
    /* Dark Main */
    color: #1D1F22;
}

.checkout-main .address-block .address-details {
    font-style: normal;
    font-weight: 390;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    color: #404040;
}

.checkout-main .address-block .default-round {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background-color: #000000;
    border-radius: 50%;
}

.checkout-main .address-block .btn-row {
    gap: 12px;
}

.checkout-main .address-block .underline-btn {
    padding: 0;
    font-weight: 450;
    font-size: 16px;
    line-height: 18px;
    text-decoration-line: underline;
    background-color: white;
    color: #404040;
}

.checkout-main .address-block .type-btn {
    padding: 12px 24px;
    height: 42px;
    font-weight: 450;
    font-size: 16px;
    line-height: 18px;
    color: #404040;
    background-color: white;
    border: 1px solid #404040;
}

.checkout-main .address-block .type-btn.active {
    color: white;
    background: #404040;
}

.checkout-main .checkout-container-confirm .column1,
.checkout-main .checkout-container-confirm .column2 {
    font-style: normal;
    font-size: 16px;
    line-height: 130%;
}

.checkout-main .cart-items-container .cart-item-details {
    width: 67.5%;
}

.bg-grey {
    background-color: #F7F7F7;
}

.payment-mode-head {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
}

.alert-close.grey {
    width: 15px;
    height: 15px;
}

.select-pay_model {
    display: flex;
    column-gap: 12px;
    align-items: center;
    background: #F6F6F6;
    border-radius: 4px;
    padding: 0 12px;
    height: 69px;
}

.select-pay_model.active {
    border: 1px solid #005EA6;
}

.pay-continue {
    max-width: 100%;
    width: 426px;
    height: 64px;
    border-radius: 6px;
    display: grid;
    place-items: center;
    font-size: 18px;
    font-weight: 420;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;

}

.round-box {
    width: 14px;
    height: 14px;
    background-color: white;
    border-radius: 50%;
    vertical-align: middle;
    border: 1px solid #777D82;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.round-box:checked {
    background-image: url("/assets/blue-round.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 8.75px;
}

.select-pay_model .razorpay {
    width: 188px;
    object-fit: contain;
}

.select-pay_model .ccavenue {
    width: 180px;
    object-fit: contain;
}

.checkout-heading.failed {
    column-gap: 12px;
}

.checkout-heading.failed img {
    width: 21px;
    height: 21px;
}

.try-again {
    color: #2C73EB;
}

.quantity-container {
    display: flex;
    align-items: center;
    column-gap: 11px;
}

.quantity-type-container {
    column-gap: 10px;
}

.quantity-container .reduce,
.quantity-container .increase {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #404040;
    width: 18px;
    height: 18px;
    color: #404040;
    border-radius: 4px;
}

.quantity-container .reduce.disabled,
.quantity-container .increase.disabled {
    cursor: default;
}

.quantity-container .qty {
    color: #404040;
}

.side-desk.desk-only .cart-items-container .cart-item {
    aspect-ratio: 2.6;
}

.cart-item-details {
    margin-top: 10px;
    margin-bottom: 8px;
}

.side-desk .cart-item-details {
    margin-top: 8px;
}

.side-desk .cart-item-titleprice .cart-item-title {
    font-size: 18px;
    line-height: 22px;
}

.coupon-container {
    margin-top: 21px;
    margin-bottom: 21px;
}

.quantity-container {}

.coupon-box {
    column-gap: 12px;
    display: flex;
    align-items: center;
}

.coupon-box .coupon-input {
    border: 1px solid #6A6969;
    height: 48px;
    border-radius: 4px;
    padding: 12px;
    font-size: 16px;
    line-height: 24px;
}

.coupon-input.success {
    border-color: #219653;
}

.coupon-input.invalid {
    border-color: #FF3939;
}

.invalid-coupon-code {
    font-size: 16px;
    font-weight: 390;
    line-height: 20.8px;
    color: #FF3939;
}

.add-coupon {
    cursor: pointer;
    font-size: 16px;
    font-weight: 420;
    line-height: 24px;
    text-align: left;
    color: #404040;
    margin-top: 21px;
    margin-bottom: 21px;
}

.coupon-box .success-box {
    background-color: #D9FFCC;
    width: 24px;
    min-width: 24px;
    height: 24px;
    display: grid;
    place-items: center;
    border-radius: 12px;
    position: absolute;
    right: 16px;
    top: 12px;
}

.coupon-box .success-box img {
    width: 15px;
    object-fit: contain;
}

.coupon-box .coupon-input::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    font-family: "Brandon Grotesque";
}

.coupon-box .coupon-input::-webkit-input-placeholder {
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    font-family: "Brandon Grotesque";
}

.coupon-box .coupon-input:-ms-input-placeholder {
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    font-family: "Brandon Grotesque";
}

.coupon-box .coupon-input::-moz-placeholder {
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    font-family: "Brandon Grotesque";
}

.coupon-box .coupon-input:-moz-placeholder {
    font-size: 16px;
    line-height: 24px;
    color: #404040;
    font-family: "Brandon Grotesque";
}

.coupon-box .apply-btn {
    height: 48px;
    width: 102px;
    min-width: 102px;
    border-radius: 4px;
    font-size: 16px;
    line-height: 24px;
    background-color: #404040;
}

.quantity-container {
    display: flex;
    align-items: center;
    column-gap: 11px;
}

.quantity-type-container {
    column-gap: 10px;
}

.quantity-container .reduce,
.quantity-container .increase {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid #404040;
    width: 18px;
    height: 18px;
    color: #404040;
    border-radius: 4px;
}

.quantity-container .reduce.disabled,
.quantity-container .increase.disabled {
    cursor: default;
}

.quantity-container .qty {
    color: #404040;
}

.side-desk.desk-only .cart-items-container .cart-item {
    aspect-ratio: 2.6;
}

.cart-item-details {
    margin-top: 10px;
    margin-bottom: 8px;
}

.side-desk .cart-item-details {
    margin-top: 8px;
}

.side-desk .cart-item-titleprice .cart-item-title {
    font-size: 18px;
    line-height: 22px;
}

@media screen and (max-width: 575px) {
    .checkout-heading {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
    }

    .checkout-sub-heading {
        font-size: 16px;
        line-height: 20px;
    }

    #checkout .cart-items-container .cart-item .remove-container {
        width: 250px;
        min-width: 250px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .cart-remove-button {
        margin-right: 0;
    }

    .checkout-main .accordion__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 18px;
        height: 48px;
    }

    .checkout-main .order-detail .accordion__button::before {
        top: 34%;
    }

    #checkout .checkout-shopping_bag {
        padding-left: 7%;
        padding-right: 7%;
    }

    #checkout .fields-sec input::-webkit-input-placeholder {
        /* Edge */
        font-size: 14px;
        line-height: 20px;
    }

    #checkout .fields-sec input::-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-size: 14px;
        line-height: 20px;
    }

    #checkout .fields-sec input::placeholder {
        font-size: 14px;
        line-height: 20px;
    }

    .checkout-main .row {
        margin-left: 0;
        margin-right: 0;
    }

    .checkout-main #checkout-confirm {
        padding: 0 15px;
    }

    .checkout-main .select-default p.brandon {
        font-weight: 390;
        font-size: 14px;
        line-height: 24px;
    }

    .checkout-main .address-block {
        padding: 12px;
    }

    .checkout-main .address-block .type-btn {
        font-style: normal;
        font-weight: 420;
        font-size: 14px;
        line-height: 24px;
        padding: 6px 12px;
    }

    .editaddress-pop {
        display: flex;
        flex-direction: column;
        padding: 18px;
    }

    .editaddress-pop .row {
        margin-left: 0;
        margin-right: 0;
    }

    .checkout-main .address-block .underline-btn {
        font-style: normal;
        font-weight: 450;
        font-size: 12px;
        line-height: 18px;
    }

    .checkout-main .checkout-container-confirm .side-desk .pricetable {
        background-color: #FFFFFF;
    }

    .checkout-main .checkout-container-confirm .checkout-sec .review_accordion {
        width: 100%;
    }

    .checkout-main .checkout-container-confirm .side-desk {
        width: 100%;
    }

    .checkout-main .order-detail .accordion__button .summary-text {
        font-size: 16px;
        line-height: 24px;

        /* identical to box height, or 150% */

        color: #404040;
        gap: 12px;
    }

    .checkout-main .order-detail .accordion__button .price {
        font-style: normal;
        font-weight: 450;
        font-size: 24px;
        line-height: 160%;

        /* or 38px */
        text-align: right;

        /* Dark Main */
        color: #1D1F22;
    }

    .side-desk .d-flex {
        justify-content: space-between;
        margin-bottom: 10px;
    }

    .checkout-main .checkout-cart-item-img #frame-container {
        padding: 0;
    }

    .checkout-main .order-detail .total-details p {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 160%;

        /* or 38px */

        /* Dark Main */
        color: #1D1F22;
    }

    .checkout-main .order-detail .accordion__button .cart {
        width: 20px;
        height: 20px;
    }

    .checkout-main .cart-items-container .cart-item {
        height: auto;
        aspect-ratio: auto;
        padding: 15px;
    }

    .checkout-main .cart-item-titleprice .cart-item-title {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
    }

    .checkout-main .cart-item-titleprice .cart-item-description {
        font-style: normal;
        font-weight: 450;
        font-size: 14px;
        line-height: 130%;
    }

    .checkout-main .checkout-container {
        width: 100%;
    }

    .checkout-payment .spinner3 {
        width: 18px;
        height: 18px;
        min-width: 18px;
        position: relative;
        margin-left: 16px;
    }

    .checkout-payment .spinner3 .loader {
        width: 18px;
        height: 18px;
        min-width: 18px;
        position: absolute;
    }

    .checkout-payment .spinner3 .loader .loader-parent {
        width: 18px;
        height: 18px;
        min-width: 18px;
    }

    .checkout-payment .spinner3 .loader .loader-parent .circle {
        width: 16px;
        height: 16px;
        min-width: 16px;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 2.2px solid #79797950;
        border-top: 2.2px solid #797979;
        --bg: #808080;
        --border: #80808050;
        animation: spinner3 800ms linear infinite;
    }

    .checkout-main .order-detail .total-sub-details p {
        font-size: 18px;
        font-weight: 400;
    }
}

@media screen and (max-width:993px) and (min-width: 565px) {
    .checkout-main .cart-items-container .cart-item {
        aspect-ratio: 2;
    }
}