.gallery-details .brandon .title {
  font-weight: 330;
  font-size: 16px;
  color: #404040;
}

.gallery-details .brandon .detail {
  font-weight: 500;
  font-size: 16px;
  color: #404040;
}

.carousel-bgcolor {
  background-color: #f8f8f8;
}

.gallery-list {
  justify-content: center;
}

.gallery-list .frame-ind img {
  height: auto;
}

.gallery-wall .thumb {
  border: 0.2px solid #000000;
  height: auto;
}

.gallery-wall .gallery-img {
  width: 100%;
}

.gallery-wall .description {
  font-weight: 420;
  font-size: 18px;
  line-height: 130%;
  color: #1d1f22;
}

.gallery-wall .tab {
  border-bottom: 1px solid #f1f2f3;
}

.gallery-wall .tab .description {
  cursor: pointer;
}

.gallery-wall .input-pin input::placeholder {
  color: #1d1f22;
  font-size: 18px;
}

.gallery-wall .main-heading {
  font-size: 28px;
  margin-bottom: 3%;
}

.gallery-wall .input-pin {
  margin-top: 6%;
}

.gallery-wall .price_cart {
  margin-top: 30px;
  margin-bottom: 50px;
}

.gallery-wall .select-carou .carousel-bgcolor {
  width: 100%;
  position: relative;
}

.gallery-wall .select-carou .carousel-bgcolor::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.gallery-wall .select-carou .carousel-bgcolor img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  padding: 5%;
}

.gallery-wall .tools .zoom-button-container {
  display: flex;
}

.alert-popup {
  background-color: white;
  border-radius: 8px;
  padding: 8% 5%;
  width: 465px;
  box-shadow: 4px 4px 50px rgba(0, 0, 0, 0.15);
}

.alert-popup .zoom-close {
  position: absolute;
  right: 15px;
  top: 15px;
}

.alert-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  /* Secondary/Dark Color */
  color: #54595E;
  margin-bottom: 30px;
}

.alert-desc {
  font-style: normal;
  font-weight: 420;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  color: #8D8F9A;
  text-align: center;
  margin-bottom: 30px;
}

.alert-continue {
  font-weight: 420;
  font-size: 16px;
  line-height: 23px;
  margin-right: 4%;
  /* identical to box height */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #4F4F4F;
  border: 1px solid #4F4F4F;
  box-sizing: border-box;
  border-radius: 8px;
  width: 48%;
}

.alert-proceed {
  background: #4F4F4F;
  border-radius: 8px;
  font-weight: 420;
  font-size: 16px;
  line-height: 23px;
  width: 48%;

  /* identical to box height */
  display: flex;
  align-items: center;
  justify-content: center;

  /* Grey / Light */
  color: #F5F5F5
}

.gallery-wall .gift-heading p {
  max-width: none;
}

@media screen and (min-width: 575px) {
  .gallery-select .gallery-detail {
    width: 75%;
  }

  .select-carou {
    align-items: center;
  }
}

@media screen and (max-width: 575px) {
  .gallery-select .gallery-detail {
    font-size: 8px;
    width: 90%;
  }

  .alert-popup {
    width: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .alert-popup p {
    font-size: 12px;
  }

  .alert-continue {
    font-size: 12px;
    line-height: 13px;
  }

  .alert-proceed {
    font-size: 12px;
    line-height: 13px;
  }

  .gallery-select .frame-ind {
    border: 1px solid #f1f2f3;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
    flex: 0 0 48%;
    margin-right: 1%;
    margin-left: 1%;
    height: min-content;
  }

  .gallery-select .frame-ind img {
    height: 150px;
  }

  .gallery-select .sub-heading {
    font-size: 12px;
  }

  .gallery-select .frame-ind .brandon {
    font-size: 8px;
  }

  .galSub {
    font-size: 12px;
  }
}

/*awr*/



@media only screen and (min-width: 575px) {
  .frame-ind {
    flex: 0 0 48%;
  }

  .galSub {
    font-size: 16px;
    white-space: pre-line;
  }
}

@media only screen and (min-width: 992px) {
  .frame-ind {
    flex: 0 0 31%;
  }

  .galSub {
    font-size: 20px;
  }
}