.share-box {
    position: absolute;
    background-color: #e8e8e8;
    z-index: 9;
    top: 32px;
    left: 50%;
    right: 0;
    transform: translate(-50%);
    margin-left: auto;
    margin-right: auto;
    column-gap: 15px;
    width: 114px;
    height: auto;
    padding: 12px;
    border-radius: 5px;
    box-shadow: 4px 4px 50px #ffffffce;
}

.share-box img {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.share-btn {
    cursor: pointer;
    justify-content: end;
    height: 28px;
    object-fit: contain;
    margin-bottom: 12px;
}

.diamond-arrow {
    width: 10px;
    height: 10px;
    background-color: #e8e8e8;
    transform: rotate(45deg);
    position: absolute;
    top: 24px;
    left: 0;
    right: 0;
    margin: 5px;
}

.shopArt-typeSelect .tools {
    margin-top: 12px;
}

.tools {
    margin-top: 12px;
    column-gap: 28px;
}

.tools .tool-icon {
    width: 20px;
    height: 20px;
    object-fit: contain;
}

.shopArt-typeSelect .frame-edit-preview-container .share-btn {
    height: 28px;
}

.shopArt-typeSelect #frame-3d {
    padding-bottom: 0;
}

.tools .zoom-button-container,
.giftingDetails-page .tools .zoom-button-container {
    bottom: auto;
    right: auto;
}

@media screen and (max-width:575px) {

    .share-btn,
    .shopArt-typeSelect .frame-edit-preview-container .share-btn {
        height: 24px;
        margin-bottom: 0px;
    }

    .shopArt-typeSelect .tools {
        margin-top: 16px;
    }

    .shopArt-typeSelect .tools .tool-icon {
        height: 20px;
    }
}