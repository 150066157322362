@media screen and (max-width: 575px) {
    .delete-add-desk {
        display: none;
    }

    .address-list-item .btns {
        flex-direction: column;
        align-items: flex-end;
    }

    .list-mob {
        display: none;
    }

    .order-thumb {
        margin-bottom: 5%;
    }

    .small-bold:hover {
        color: #404040;
    }

    .order-list-item .App #frame-3d {
        left: 0;
    }

    .order-list-item .order-list-image {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto !important;
    }

    .order-list-image-details>div {
        margin-bottom: 4%;
    }
}

.clear-all {
    border: 1.8px solid #404040;
}

.accordion__item {
    border-bottom: 1px solid #e8e8e8;
}

@media screen and (min-width: 575px) {
    .delete-add-mob {
        display: none;
    }

    .mobile-div {
        display: none;
    }

    .address-list-item button:first-child {
        margin-right: 10px;
    }

    .shipping-address {
        padding: 2%;
    }

    .singleorder-ships .ship-details {
        font-family: 'CircularStd', serif;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 13px;
        letter-spacing: 0px;
        text-align: left;
    }

    .singleorder-ships .order-list-price {
        font-family: 'CircularStd', serif;
    }

    .singleorder-ships-table {
        padding-right: 0;
        display: flex;
        align-content: space-between;
    }

    .singleorder-ships {
        height: 100%;
        padding: 10% 6% 6% 6%;
        display: flex;
        align-content: space-between;
    }

    .singleorder-ships .order-list-price {
        padding-bottom: 10%;
    }

}

.order-thumb .cart-item p.brown {
    display: none;
}

.order-thumb .shipping-list-container {
    margin-bottom: 2%;
}

.order-accordions .order-list-item {
    flex-direction: column;
}

.order-accordions .order-list-image {
    margin-right: 0;
}

.order-accordions .order-list-view {
    display: none;
}

.order-accordions .order-list-qty {
    display: none;
}

.order-accordions .order-thumb .order-list-image>img {
    width: 100%;
}

.order-accordions .accordion__panel {
    padding: 0;
}

.order-accordions .accordion__button {
    background-color: #fff;
}

.order-accordions .accordion {
    border: none;
}

.order-number {
    justify-content: space-between;
}

.d-menu-item {
    cursor: pointer;
}

.delete-add-mob img {
    height: 15px;
    margin-bottom: 40px;
}

.address-list-item button {
    padding: 10px 25px;
    font-size: 0.8rem;
}


.profile-menu-item img {
    height: 20px;
}

.profile-menu-item p {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    /* line-height: 18px; */
    letter-spacing: 0px;
    text-align: left;
    /* font-family: CircularStd; */

}

.profile-menu-item {
    display: flex;
    padding: 15px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--grey);
}

.profile-edit .accordion__button {
    background-color: white;
    border: none;
}

.profile-edit .accordion__panel {
    padding: 15px;
}

.profile-edit-contianer {
    margin-top: 5%;
}

.profile-edit .accordion__button {
    padding: 20px 15px 20px 15px;
    font-size: 0.85rem;
    font-weight: 900;
}

.profile-edit .accordion__item+.accordion__item {
    border-top: none;
    border-bottom: 1px solid var(--grey);
}

.accordion__button:focus {
    outline: none;
}

.small-normal {
    /* font-weight: 900; */
    font-size: 0.9rem;
    /* font-family: CircularStd; */
    font-weight: 400;
}

.small-bold {
    font-weight: 900;
    font-size: 1rem;
    /* font-family: CircularStd; */
}


.profile-input {
    height: 44px;
    border: 1px solid #4F4F4F;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 20px;
    font-size: 16px;
}

.profile-input::-webkit-input-placeholder {
    /* Edge */
    color: #4F4F4F;
    font-size: 16px;
    font-weight: 500;
    /* font-family: CircularStd; */
}

.profile-input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #4F4F4F;
    font-size: 16px;
    font-weight: 500;
    /* font-family: CircularStd; */
}

.profile-input::placeholder {
    color: #4F4F4F;
    font-size: 16px;
    font-weight: 500;
    /* font-family: CircularStd; */
}

#personal_data button {
    width: 100%;
    font-size: 0.8rem;
}


.profile-header {
    display: flex;
    align-items: center;
    margin-top: 5%;

}

.profile-header img {
    height: 20px;
    transform: rotate(180deg);
}


/* ADDRESS LIST */
.address-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--grey);

}

.shipping-list-container {
    margin-top: 5%;
    margin-bottom: 15%;
}

.open-orders .shipping-list-container {
    margin-bottom: 0;
}

.address-list-item p {
    width: 40%;
}

.my-orders-list .list-mob {
    display: none;
}

.my-orders-list .order-list-item .order-list-image {
    width: 150px;
}

.address-list-item .btns {
    display: flex;
}

.absolute-btn {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    padding-bottom: 10px;
}


/* profiledetails */
@media screen and (max-width: 799px) {
    .desktop-div {
        display: none;
    }

    .mobile-div {
        margin-bottom: 40px;
    }

}

@media screen and (min-width: 800px) {
    .desktop-div {
        display: flex;
        margin-top: 70px;
        margin-bottom: 70px;
    }
}


.desktop-div>div:first-child {
    width: 30%;
    margin-right: 51px;
}

.desktop-div>div:nth-child(2) {
    width: 60%;
}

.Desktop-buttons {
    border-bottom: solid 1px #F1F2F3;
    display: flex;
}

.Desktop-buttons>a>button :focus {
    border-bottom: solid #1D1F22;
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    outline: 0 !important;
}


.Desktop-buttons>a>button {
    border-top: none;
    border-left: none;
    border-right: none;
    /* font-family: Circular Std; */
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    display: flex;
    align-items: center;
    text-align: center;
    color: #72757E;
    ;
    background: #ffffff;
    margin-right: 15px;
    padding: 5px 0px;
    outline: 0 !important;


}

/* .Desktop-buttons > a > button {
color: #72757E;
color: #1D1F22;
} */


/* //desktopmenu */
.d-menu {
    background: #F1F2F3;
    padding: 40px;

}

.d-menu-heading {
    font-family: "GT Super Bold";
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 120%;
    color: #1D1F22;
}

.d-menu-item1 {
    /* font-family: Circular Std; */
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 130%;
    color: #1D1F22;
    margin: 25px 0px 0px;
}

.d-menu-item {
    /* font-family: Circular Std; */
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    color: #404040;
    margin: 20px 0px 0px;
}

/* personalData */
@media screen and (min-width: 800px) {
    #personal_data {
        margin-top: 40px;
    }

    .small-normal {
        /* font-family: Circular Std; */
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        color: #404040;
    }

    .fields-sec>input {
        /* font-family: Circular Std; */
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #1D1F22;
        margin-top: 20px;
        /* height: 69px; */
    }

    .bullets.row {
        margin: 8% 0;
    }

    .row {
        margin: 0px;
    }

    #personal_data .change-details-btn {
        width: 240px;
        height: 56px;
        margin-top: 40px;
    }

    .policy-text {
        /* font-family: Circular Std; */
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 130%;
        color: #000000;
        padding: 0px;
    }


}

/* shippingList */
@media screen and (min-width: 800px) {
    #ship-change-details-btn, .absolute-btn .ship-change-details-btn {
        width: 240px;
        height: 56px;
        margin-top: 40px;
    }

    .btn-shiplist {
        width: 240px;
    }

    .address-list-item>p {
        /* font-family: Circular Std; */
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #1D1F22;

    }

    .address-list-item {
        padding: 30px 0px;
    }

    /* .btns > button{
        width: 128px;
        height: 56px;
    } */
    #profile-edit>.pad-0 {
        padding: 0px;
    }
}

@media screen and (max-width: 799px) {
    .address-list-item>p {
        /* font-family: Circular Std; */
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 130%;
        display: flex;
        align-items: center;
        color: #1D1F22;
    }
}

/* address change */
@media screen and (min-width: 800px) {
    #ship-change-details-btn {
        width: 240px !important;
        height: 56px;
        margin-top: 40px;
    }
}

/* my orders */
.order-list {
    padding-bottom: 25px;
}

.order-list-item {
    background: #F1F2F3;
}

.order-list-item {
    padding: 40px;
    display: flex;
}

.order-list-image {
    margin-right: 30px;
}

.order-list-title {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 160%;
    display: flex;
    flex-direction: column;
}

.order-list-dimensions, .order-list-matstyle, .order-list-framesize, .order-list-qty {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.order-list-price {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
}

.order-list-image-details-span {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 130%;
    color: #8C8C8C;
}

.order-list-image-details>div {
    /* display: flex;
    flex-direction: column;
    justify-content: flex-end; */
}

.order-list-item #frame-3d:hover .three_d_box_container {
    transform: none;
}

.order-list-item .order-list-image {
    height: auto;
    width: 200px;
}

.order-list-item .order-list-image, .order-list-item .order-list-image .App, .order-list-item .order-list-image #frame-container {
    max-height: 200px;
    min-height: 200px;
}

.order-list-item #frame-3d {
    align-items: center;
    top: 0%;
    left: -8%;
}

.order-list .order-list-item .order-list-image .images {
    height: auto;
    max-height: 120px;
    object-fit: contain;
    margin: 8% 0;
}

@media screen and (max-width: 799px) {
    .order-list-image>img {
        width: 124px;
        height: auto;
    }

    .order-list-item {
        padding: 15px;
        /* display: flex; */
    }

    .order-list-image-details-di {
        display: none;
    }

    .order-list-title {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        font-style: normal;
        font-weight: 600;
    }

    .order-list-price {
        font-size: 14px;
        font-style: normal;
        font-weight: normal;
        font-family: unset;
        align-items: center;
        display: flex;
    }

    .order-list-qty {
        font-size: 12px;
        font-style: normal;
        font-weight: normal;

    }

    .order-list-view {
        font-size: 14px;
        display: flex;
        align-items: flex-end;
        font-style: normal;
        font-weight: normal;
        color: #6C3F2C;
    }

}

@media screen and (min-width: 800px) {
    .order-list-image>img {
        width: 212px;
        height: auto;
    }

    .order-list-image-details-mob {
        display: none;
    }
}