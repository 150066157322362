.my-account-box {
    padding: 40px;
    border: 1px solid #404040;
    border-radius: 12px;
    max-width: 655px;
}

.my-account-box label {
    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 23px;
    color: #404040;
    display: flex;
    margin-top: 20px;
}

.my-account-box input {
    width: 100%;
    height: 52px;
    border: 1px solid #D1D1D1;
    border-radius: 8px;
    padding: 14px 12px;
    gap: 10px;
    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 23px;
}

#personal_data .main-heading {
    margin-bottom: 28px;
}

#personal_data .my-account-box .change-details-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 14px 20px;
    height: 52px;
    width: 100%;

    /* Dark / Medium */
    background: #4F4F4F;
    border-radius: 8px;
    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 23px;

    /* identical to box height */
    display: flex;
    align-items: center;

    /* Grey / Light */
    color: #F5F5F5;
}


@media screen and (max-width:575px) {
    .my-account-box {
        padding: 15px;
    }

    .my-account-box .d-menu-heading {
        font-size: 16px;
    }

    .profile-edit .address-block .btn-row {
        margin-left: 0;
        margin-right: 0;
    }

    .profile-saved-address .address_add-button {
        margin-left: 15px;
        margin-right: 15px;
    }

    #personal_data .change-details-btn {
        margin-top: 20px;
    }
}