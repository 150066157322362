.otp_login {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.otp_login .login-or-sign-up .login-heading {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
}

.otp_login .login-or-sign-up .login-label {
    font-style: normal;
    font-weight: 420;
    font-size: 16px;
    line-height: 23px;
    color: #5F5F60;
}

.otp_login .login-or-sign-up .login-label.font-14 {
    font-size: 14px;
    line-height: 20px;
}

.otp_login .profile-input {
    height: 42px;
    background: #FFFFFF;
    border: 1px solid rgba(64, 64, 64, 0.5);
    border-radius: 4px;
}

.profile-select {
    background: #FFFFFF;
    border: 1px solid rgba(64, 64, 64, 0.5);
    border-radius: 4px;
    height: 42px;
}

.popup-container .otp_login .login-pop .black-btn {
    font-weight: 420;
    font-size: 18px;
    line-height: 26px;
    border-radius: 6px;
    background: #404040;
    height: 64px;
}

.popup-container .otp_login .login-pop .profile-input {
    height: 64px;
    background: #FFFFFF;
    border: 1px solid #404040;
    border-radius: 6px;
}

.otp_login .otp-input-container {
    margin-top: 40px;
    justify-content: center;
}

.otp_login .otp-input-tag {
    width: 45px !important;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid rgba(64, 64, 64, 0.5);
    border-radius: 4px;
    margin-bottom: 25px;
}

.otp_login .otp-input-tag {
    width: 45px !important;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid rgba(64, 64, 64, 0.5);
    border-radius: 4px;
    margin-bottom: 25px;
}

.mobile-input {
    gap: 12px;
}

.mobile-input .profile-select {
    width: 60px;
    padding: 10px 12px;
}

input[type="submit"] {
    text-align: center;
}

.otp-submit-button {
    background: #404040;
    border: 1px solid #404040;
    color: #FFFFFF;
    text-align: center;
    margin: 20px auto 6px auto;
    width: 80%;
    height: 36px;
    justify-content: center;
    border-radius: 2px;
    font-weight: 420;
    font-size: 12px;
    line-height: 18px;

    /* identical to box height, or 144% */
}

.pe-none {
    cursor: default;
    pointer-events: none;
    opacity: 0.7;
}

.otp_login .pe-auto strong {
    cursor: pointer;
}

@media screen and (min-width: 575px) {
    .otp_login .otp-input-container {
        gap: 12px;
    }

    .otp-submit-button {
        margin: 40px auto 12px auto;
        width: 250px;
        height: 42px;

        border-radius: 4px;
        font-weight: 420;
        font-size: 18px;
        line-height: 26px;

        /* identical to box height, or 144% */
        text-align: center;

    }

    .otp_login .mobile-input {
        width: 340px;
        margin: 0 auto;
    }
}

@media screen and (max-width: 575px) {
    .otp_login .otp-input-container {
        margin-top: 30px;
    }
}