.left-side p,
p.count {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #404040;
  text-transform: capitalize;
}

.frame-list #frame-container {
  min-height: auto;
  height: auto;
  align-items: center;
  background-color: transparent;
}

.frame-ind #frame-3d {
  top: 0%;
  left: 0%;
  width: auto;
}

.dark-main {
  color: #1d1f22;
}

.frame-only-list .details {
  display: none;
}

.frame-select-head2 {
  font-size: 20px;
}

.frame-list .frame-ind.frame-image {
  pointer-events: none;
}

@media screen and (min-width: 575px) {
  #frame-select #select_size h2.headmedium {
    margin-top: 70px;
  }

  .frame-price p {
    font-size: 16px;
  }

  .frame_edit_container .frame-list {
    width: 100%;
  }

  .frame-list .frame-edit-preview-edit {
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .frame-list {
    display: flex;
    margin-right: 2%;
    flex-wrap: wrap;
    width: 100%;
  }

  .frame-container .frame-list .row {
    width: 100%;
  }

  .frame-ind {
    flex: 0 0 31%;
    height: min-content;
    border: 1px solid #f1f2f3;
    margin-left: 2%;
    background-color: white;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .frame-only-list .frame-ind {
    flex: 0 0 31%;
    height: min-content;
    margin-left: 2%;
  }

  .frame-only-list .frame-image {
    height: auto;
    box-shadow: none;
    position: relative;
    background-color: transparent;
    padding: 0;
    border: none;
  }

  .frame-only-list .frame-image img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  .frame-black {
    flex: 0 0 31%;
    margin-left: 2%;
  }

  .three_d_box_container {
    box-shadow: rgb(41 51 68 / 53%) 4px 8px 11px 0px;
  }

  .frame-list .three_d_box_container {
    box-shadow: rgba(41, 51, 68, 0.5) 2px 2px 5px 2px;
  }

  .filter-box-container {
    display: flex;
    padding-right: 20px;
    max-width: 35%;
  }

  .frame-container {
    display: flex;
    justify-content: space-between;
  }

  div.search-box {
    width: 18%;
  }

  div.search-box img {
    right: 10px;
  }

  .filter-btn {
    visibility: hidden;
  }

  div.colors {
    margin-bottom: 40px;
  }
}

.review-menu .frame-details {
  font-size: 12px;
}

@media screen and (max-width: 575px) {
  .frame-only-list {
    display: flex;
    flex-wrap: wrap;
  }

  .frame-select-head2 {
    font-size: 8px;
  }

  #frame-container {
    padding: 5%;
  }

  .frame-only-list .frame-ind {
    width: 80%;
    height: min-content;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  }

  .frame-black {
    flex: 0 0 48%;
    margin-left: 2%;
  }

  a.frame-ind img {
    width: 100%;
    height: 300px;
  }

  .fnt-24 {
    font-size: 24px;
  }

  .filter-box-container {
    display: none;
  }

  .dont-scroll {
    position: fixed;
    overflow-y: hidden;
    min-height: 100vh;
  }

  html,
  body {
    overflow-x: hidden !important;
  }

  #filter {
    padding: 0 15px;
  }

  .filter-btn {
    visibility: visible;
  }

  .filter-box-container.mob {
    display: block;
    position: fixed;
    background-color: white;
    width: 100%;
    bottom: 0;
    top: 68px;
    z-index: 2;
    padding-top: 20px;
    overflow-y: scroll;
  }

  .filter-box-container.mob .btns {
    padding-left: 15px;
    padding-right: 15px;
  }

  .filter-box-container.mob .btns,
  .cart-btns .btns {
    display: flex;
    justify-content: space-between;
  }

  .filter-box-container.mob .btns button,
  .cart-btns .btns button {
    width: 49%;
    padding: 10px;
  }

  .filter-box-container.mob .btns button.bgsecondary {
    border: 1px solid #404040;
  }

  .filter-search-box .search-box input {
    padding-right: 25%;
  }

  .filter-search-box .search-box img {
    right: 7px;
    height: 13px;
    bottom: 11px;
  }

  .frame-container {
    padding-left: 5%;
    padding-right: 5%;
  }

  .filter-search-box {
    padding-left: 5%;
    padding-right: 5%;
  }

  #filter .colors h4 {
    font-size: 24px;
    font-weight: 700;
  }
}

.colors h4 {
  font-size: 16px;
  font-family: "Noto Serif", serif;
  font-weight: 600;
  margin-bottom: 5%;
}

.colors {
  margin-bottom: 10%;
  margin-top: 5%;
}

#filter {
  width: 100%;
}

.left-side {
  display: flex;
  cursor: pointer;
}

.count {
  text-align: right;
  width: 40%;
  justify-content: flex-end;
}

.filter-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.filter-checkbox {
  height: 20px;
  width: 20px;
  border: 1px solid grey;
  margin-right: 10px;
}

.filter-search-box {
  justify-content: space-between;
  display: flex;
  margin: 3% 0;
  max-width: 100%;
}

.filter-btn {
  display: flex;
  align-items: center;
}

.filter-btn p {
  font-size: 12px;
  font-weight: 900;
}

.filter-btn img {
  height: 15px;
  margin-right: 5px;
}

.search-box {
  width: 40%;
  display: flex;
  position: relative;
  flex-direction: row;
}

.search-box input {
  border-top: none;
  border-right: none;
  border-left: none;
  padding-left: 5px;
  overflow: hidden;
  padding-bottom: 5px;
  padding-right: 20%;
}

.search-box input::-webkit-input-placeholder {
  /* Edge */
  font-size: 12px;
  color: var(--secondary-light);
}

.search-box input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 12px;
  color: var(--secondary-light);
}

.search-box input::placeholder {
  font-size: 12px;
  color: var(--secondary-light);
}

.search-box img {
  height: 20px;
  position: absolute;
  right: 25px;
  bottom: 7px;
}

.frame-ind {
  padding: 0px;
}

.frame-item {
  padding: 15px;
  padding-bottom: 30px;
  margin-bottom: 15px;
padding-top: 24px;
}

.frame-ind img {
  width: 100%;
  height: 250px;
  object-fit: contain;
}

.name-price {
  text-transform: capitalize;
}

.fnt-16 {
  font-size: 16px;
}

.padslider select {
  border: none;
}

.name-price {
  margin-top: 10px;
}

.frame-only-list.frame-list .name-price {
  margin-top: 10px;
}

.frame-list .name-price {
  margin-top: -2%;
}

.frame-list #frame-3d {
  padding: 5%;
}

.price {
  font-weight: bold;
}

/*awr*/

@media screen and (max-width: 575px) {
  .awr-frame-ind {
    margin-left: 20% !important;
    flex: 0 0 60% !important;
  }

  .only-image {
    height: 100% !important;
  }
}