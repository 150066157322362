.type_icons{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.type_icon{
    text-align: center;
    padding: 25px 15px;
    width: 40%;
    margin-right: 4%;
    margin-bottom: 5%;
}

.type_icon img{
    height: 60px;
    margin-bottom: 10px;
}

.type_icon p{
    margin-bottom: 0;
    color: var(--secondary);
    font-size: 0.8rem;
    font-weight: 600;
}

.type_icon p:hover{
    text-decoration: none;
}

.no-products{
    display: flex;
    justify-content: center;
    margin: 5% auto;
    color: var(--secondary);
}
.no-products h3{
    margin-top: 0;
}

@media screen and (min-width: 575px) {
    .type_icon {
        padding: 55px 25px;
        width: 22%;
        margin-right: 4%;
        margin-top: 2%;
    }
    .header-margin{
        margin-top: 5rem;
    }
    .margin-bottom{
        margin-bottom: 5rem;
    }
    .type_select{
        margin-top: 5rem;
        margin-bottom: 5%;
    }
    .type_icon img {
        height: 90px;
    }
    .type_icon p{
        font-size: 24px;
    }
}

@media screen and (max-width: 575px) {
    .type_icons{
        min-height: 30vh;
    }
}