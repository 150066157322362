:root {
    --blue: #1e90ff;
    --white: #ffffff;
}

@media screen and (width: 991px) {
    .menu-desk {
        display: flex;
    }
}

@media screen and (min-width: 992px) {
    .hamburger.icon {
        display: none;
    }

    .mobile_header .hamburger {
        display: none;
    }

    .menu {
        display: none;
    }

}

.mobile_header {
    height: 64px;
}

@media screen and (max-width:991px) and (min-width: 575px) {
    .menu {
        position: fixed;
        background-color: white;
        width: 100%;
        height: 100%;
        left: 0;
        top: 60px;
        z-index: 99;
        height: 97%;
        padding: 10% 0;
        overflow-y: hidden;
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        margin: 0;
        overflow-x: hidden;
    }

    .menu-list {
        width: 85% !important;
        max-width: 85% !important;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .menu-list>a {
        font-family: "GT Super Bold";
        font-size: 20px;
        padding-bottom: 5%;
    }

    .menu-list>.login {
        font-size: 20px;
    }
}

.menu-list>a {
    width: 33%;
    display: block;
}


@media screen and (min-width: 575px) {
    .cart_icon img {
        margin-left: 15px;

    }

    #mobile_header .mobile_header img {
        height: 20px;
    }

    #mobile_header .mobile_header .mob_logo img {
        height: 20px;
        width: 180px;
        margin: auto;
        align-items: center;
    }

    .menu-desk {
        z-index: 9;
    }

    #mobile_header .mobile_header .mob_logo>a {
        display: flex;
        padding-top: 34px;
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    #mob_footer {
        display: none;
    }

    .close-cart {
        display: flex;
        cursor: pointer;
        flex: 1;
        margin-top: 34px;
        margin-left: -20px;
    }

    .mobile_header {
        padding-left: 0px;
    }
}

.user-popup {
    position: absolute;
    padding: 30px 18px;
    top: 34px;
    z-index: 9;
    right: 0;
    display: flex;
    width: 296px;
    height: fit-content;
    background: #FFFFFF;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    border-radius: 4px;
}

.user-popup .user-popup-container {
    width: 100%;
    height: 100%;
}

.user-popup .login-button {
    background: #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #404040;
    width: 138px;
    height: 51px;
}

.user-popup p {
    cursor: pointer;
    text-align: left;
    margin-bottom: 12px;
}

.user-popup .section {
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #D1D1D1;
}

.user-popup .section:first-child {
    padding-top: 0;
}

.user-popup .section:last-child {
    padding-bottom: 0;
    border-bottom: none;
}

.user-popup .welcome-text {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    text-align: left;
    /* identical to box height, or 150% */

    color: #000000;
}

.popup-container {
    padding: 7% 10px;
    width: 400px;
    height: fit-content;
    background-color: white;
    z-index: 99;
}

.popup-container .profile-input {
    width: 100%;
}

.popup-container .black-btn {
    padding: 5px !important;
    width: 100%;
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
    border-radius: 8px;
    color: #F5F5F5;
    background: #4F4F4F;
    height: 44px;
}

.popup-container .frgt-pwd {
    margin-top: 8%;
}

.popup-container .dnt-accnt {
    margin-top: 8%;
    font-size: 16px;
    margin-bottom: 20px;
}

.popup-container .headmedium {
    display: none;
}

.popup-container .mobile_header {
    display: none;
}

.popup-container .register {
    margin-top: 7%;
}

#mobile_header .cart-menu .close-cart {
    position: absolute;
    top: 0%;
    left: 4%;
    z-index: 999;
    width: 30px;
    height: 30px;
}

.cart-open {
    position: relative;
}

.close-cart-container {
    position: absolute;
    right: 0;
    top: 2%;
    width: 400px;
    z-index: 99;
}

.close-cart-container .close-cart {
    background-color: black;
    border-radius: 50%;
    position: absolute;
    z-index: 99;
    padding: 3%;
}

#mobile_header .close-cart-container .close-cart img {
    width: 16px;
    height: 16px;
}

.cart-menu #cart {
    width: 100%;
}

.cart-count {
    position: absolute;
    top: -20px;
    right: -15px;
    color: white;
    background-color: black;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fixedheader {
    position: fixed;
    width: 100%;
    z-index: 999;
    top: 0;
    background-color: #ffffff;
    border-bottom: 1px solid #cbcbcb;
    transition: height .5s;
}

@media screen and (min-width:992px) {
    .header-all .header-container {
        height: 115px;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .menu-desk a.brandon:first-child {
        font-size: 14px;
        margin-left: 0px;
        margin-right: 5px;
    }

    .menu-desk a.brandon {
        font-size: 14px;
        margin-left: 5px;
        margin-right: 5px;
    }

    #mobile_header .mobile_header .mob_logo img {
        height: 16px;
        width: 177px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .menu-desk a.brandon {
        font-size: 14px;
    }

    #mobile_header .mobile_header .mob_logo img {
        height: 14px;
        width: 155px;
    }
}

@media screen and (max-width: 575px) {
    p.cart-count {
        top: -10px;
        right: -10px;
        height: 20px;
        width: 20px;
    }

    .mobile_header .mob_logo img {
        margin-top: 8px;
    }

    .popup-container.login-popup {
        width: 90%;
        position: relative;
        margin-left: 5%;
        margin-right: 5%;
    }

    .popup-overlay {
        background-color: rgba(0, 0, 0, 0.5);
    }

    .otp_login.login {
        padding: 0 16px;
    }

    .login-popup .login-close {
        top: 10px;
        right: 10px;
    }

    .login .login-heading {
        margin-bottom: 5%;
    }

    .otp_login.login .profile-input {
        margin-bottom: 10px;
    }

    #header {
        display: flex;
        align-items: center;
        width: 100%;
    }

    #header #mobile_header {
        width: 100%;
        padding: 10px 0;
    }

    #mobile_header .cart_icon {
        z-index: 99;
    }

    .mobile_header {
        height: 36px;
    }

    .mobile_header a {
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 9;
    }


    .menu-desk {
        display: none;
    }

    .menu-desk a {
        display: none;
    }

    .user-log {
        display: none;
    }

    .hamburger .close-menu {
        width: 30px;
        height: 30px;
    }

    .hamburger .open-menu {
        width: 25px;
        height: 25px;
    }

    .cart_icon .cart-open {
        width: 26px;
        height: 27px;
    }

    .menu-list .login {
        width: 100%;
    }

    .menu {
        position: fixed;
        background-color: white;
        width: 100%;
        height: 100%;
        left: 0;
        top: 50px;
        z-index: 99;
        height: 97%;
        padding: 10% 0;
        overflow-y: hidden;
        display: flex;
        flex-wrap: nowrap;
        align-items: stretch;
        margin: 0;
        overflow-x: hidden;
    }

    .menu-list {
        position: relative;
        display: inline-block;
        margin-bottom: 15px;
        font-family: Noto Serif TC;
        font-size: 24px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        left: 53px;
        align-items: stretch;
        overflow: hidden;
    }

    .menu-list>a {
        padding-bottom: 15%;
        width: 100%;
        display: block;
    }

    .menu-list>div {
        padding: 12% 0;
        font-family: 'CircularStd';
        color: #404040;
        ;
    }

    .inner-links {
        font-size: 15px;
        justify-content: space-between;
        font-family: Circular Std;
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    .login {
        font-size: small;
        font-size: 16px;
    }

    .overlay {
        background-color: white !important;
    }

    .overlay.zoom-image-overlay {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }

    .overlay .cart-open {
        padding-right: 15px;
    }

    .overlay .cart-open .cart-count {
        right: 5px;
        top: -6px;
    }

    .overlay .cart-logo {
        margin-left: -5px;
    }

    .overlay .cart-menu {
        position: absolute;
        display: flex;
        flex-wrap: nowrap;
        top: 10%;
        left: 0;
        width: 100%;
        height: 91%;
        overflow-y: auto;
        z-index: 99;
    }

    .close-cart {
        display: none;
        margin-top: 34px;
        margin-left: 0px;
    }

    .head-search-box .search-close {
        top: 7.5px;
    }

    /* #mobile_header .mobile_header .head-search-box img.searchbtn {
        margin-top: 0;
    } */
    #mobile_header .mobile_header .wishlist-icon {
        margin-left: 10px;
    }
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    top: 0;
    left: 0;
    overflow-y: hidden;
    z-index: 5;
    background-color: rgba(0, 0, 0, 0.5);
}

.cart-menu {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 9;
    display: flex;
    object-fit: cover;
    width: 400px;
    overflow-y: auto;
    -ms-overflow-style: none;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    transition: right 0.5s;
}

.cart-menu ::-webkit-scrollbar {
    display: none;
}

.menu-desk a {
    margin: 24px 15px;
    font-size: 16px;
}

.menu-desk a:first-child {
    margin: 24px 15px 24px 0;
    font-size: 16px;
}

.mobile_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.mob_logo {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.mobile_header img {
    height: 20px;
}

.mob_logo {
    text-align: center;
    justify-content: center;
}

.mobile_header .mob_logo img {
    height: 12px;
    width: 100px;
}

.cart_icon {
    text-align: right;
    z-index: 3;
}

.hamburger {
    text-align: left;
    z-index: 9;
    cursor: pointer;
}

.icon {
    flex: 1;
}

.user-log {
    margin-left: auto;
    justify-content: flex-end;
    cursor: pointer;
}

.close-cross {
    position: absolute;
    top: 10px;
    right: 10px;
}

.search-bar::before {
    width: 0px;
}

.head-search-box img {
    width: 15px;
    height: 15px;
    top: 4.5px;
    position: absolute;
    right: 3px;
}

#mobile_header .mobile_header img.searchbtn {
    height: 17px;
    margin-top: 2.5px;
    filter: opacity(0.98);
}

.head-search-box::before {
    width: 0;
}

.head-search-box {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: width .2s ease;
    height: 30px;
}

.head-search-box img {
    cursor: pointer;
}

.search-bar {
    box-sizing: border-box;
    padding: 2px 8px;
    padding-right: 20px;
    width: 100%;
    color: #4d4b4b;
    border: 1px solid #404040;
}

.suggest-box {
    position: absolute;
    top: 30px;
    left: 0;
    width: 200px;
    max-height: 60vh;
    overflow-y: auto;
    border: 1px solid #404040;
}

.suggest-box a {
    display: flex;
    padding: 2px 8px;
    background-color: white;
    color: #4d4b4b;
    border-bottom: 1px solid #8c8c8c;
    text-align: left;
}

.suggest-box a:last-child {
    border-bottom: none;
}

.cart-open img {
    height: 18.5px;
}