.articledetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 7%;
}

.blog-img img {
    height: 300px;
    object-fit: cover;
    width: 100%;
    margin-bottom: 10px;
}

.blog-details-page .blog-img img {
    height: auto;
    object-fit: contain;
}

.type-3 img,
.type-4 img {
    width: 100%;
}

.type-2-details {
    width: 80%;
}

#blog .headmedium {
    padding: 0 4%;
}

.articledetails .blog-img {
    width: 100%;
}

.single-blog-container {
    width: 66%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
}

.single-blog-container .blog-type-container {
    margin-top: 48px;
    margin-bottom: 48px;
}

.single-blog-container .main-heading,
.single-blog-container .blog-detail p,
.single-blog-container .blog-detail ul {
    width: 66%;
    margin: 0 auto 20px auto;

}

.single-blog-container blockquote {
    background: #F8F8F8;
    box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.03);
    border-radius: 4px;
    text-align: center;
    padding: 20px;
}

.single-blog-container .blog-detail img {
    width: 100%;
    margin: 0 auto 20px auto;
}

.single-blog-container .blog-detail strong {
    font-size: 32px;
}

.articledetails .blog-detail {
    width: 90%;
    margin: 0 auto;
}

.hashcolor-text {
    color: #77838F;
}

.articledetails .headmedium {
    width: 90%;
    margin: 0 auto;
}

.blog-main-description {
    margin-top: 24px;
    margin-bottom: 64px;
    font-weight: 390;
    font-size: 20px;
    line-height: 150%;
    color: #404040;
}

.blog-main-description a {
    text-decoration: underline;
}

.blog-main-description p {
    margin-bottom: 16px;
}

.blog-main-description p:last-child {
    margin-bottom: 0;
}

.blog-type-details p {
    margin-bottom: 16px;
}

.blog-type-details a {
    text-decoration: underline;
}

.blog-type-details p:last-child {
    margin-bottom: 0;
}


.articledetails .blog-img img {
    height: 400px;
    object-fit: cover;
    width: 100%;
    margin-bottom: 10px;
}

.blog-list .blog-title {
    font-size: 24px;
    font-weight: 400;
    color: hsl(210, 6%, 13%);
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    min-height: 30px;
}

.blog-list .blog-description {
    font-size: 17px;
    line-height: 27px;
    max-height: 81px;
    min-height: 81px;
    color: #77838F;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-weight: normal;
    margin-bottom: 20px;
}

.blog-list .read-more-btn {
    color: white;
    background-color: #404040;
    padding: 15px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    margin: 20px auto;
    border-radius: 4px;
    font-size: 15px;
    height: 50px;
    width: 150px;
}

.blog-list .pagination {
    margin-top: 80px;
    padding-right: 0;
}

.blog-list .pageno {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 0 4px;
}

.blog-list .pageno.current {
    background-color: #404040;
    color: white;
}

#blog {
    background-color: #F8F8F8;
}

.blog-list .single-blog {
    background-color: white;
    box-shadow: 0px 2px 48px rgba(0, 0, 0, 0.06);
    border-radius: 4px;
}

.blog-details-page .blog-main-image {
    position: relative;
    padding-top: 27.77%;
}

.type-1-imageContainer {
    position: relative;
    padding-top: 63.2%;
    margin-bottom: 20px;
}

.type-2-image-container {
    position: relative;
    padding-top: 45.69%;
    margin-bottom: 20px;
}

.type-3-imageContainer {
    margin-top: 2px;
    position: relative;
    padding-top: 78.05%;
    margin-bottom: 20px;
}

.blog-details-page .blog-main-image img,
.type-2-image-container img,
.type-3-imageContainer img,
.type-1-imageContainer img {
    position: absolute;
    inset: 0 0 0 0;
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
}

.blog-list .single-blog img {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.blog-sub-heading {
    font-size: 32px;
    line-height: 40px;
    color: #1D1F22;
    margin-bottom: 16px;
}

.blog-type-details {
    font-style: normal;
    font-weight: 390;
    font-size: 20px;
    line-height: 150%;
    color: #404040;
    /* or 156% */
    text-align: justify;
}

.blog-list .blog-description>ul,
.blog-list .blog-description>table,
.blog-list .blog-description>th,
.blog-list .blog-description>tr,
.blog-list .blog-description>td,
.blog-list .blog-description>img,
.blog-list .blog-description>code,
.blog-list .blog-description>a {
    display: none;
}

.blog-type-container.type-4 .type-text,
.blog-type-container.type-3 .type-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width: 575px) {
    .blog-detail {
        margin-bottom: 5%;
    }

    .single-blog-container .blog-type-container {
        margin-top: 30px;
        margin-bottom: 30px;
    }

    .single-blog-container .main-heading {
        font-size: 20px;
    }

    .blog-main-description {
        margin-top: 12px;
        margin-bottom: 32px;
        font-size: 14px;
    }

    .blog-type-details p {
        margin-bottom: 12px;
    }

    .blog-main-description p {
        margin-bottom: 12px;
    }

    .blog-sub-heading {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
    }

    .blog-type-details {
        font-size: 14px;
        line-height: 18px;
    }

    .blog-list .single-blog img {
        height: 200px;
    }

    .single-blog-container {
        width: 90%;
        margin-top: 40px;
    }
}