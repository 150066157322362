.wishlist-page .name-price {
    font-size: 20px;
}

.wishlist-add-box {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 84px;
    height: 38px;
}

.wishlist-page .frame-item {
    padding: 16px;
    padding-top: 24px;
    margin-bottom: 0;
}

.wishlist-page .name-price {
    margin-top: 0;
}

.wishlist-page .artist-name {
    font-size: 16px;
}

.tools .listed-heart {
    filter: opacity(0.8);
}

.cross-box {
    background-color: #C4C4C4;
    border-radius: 50%;
    display: grid;
    place-items: center;
    width: 36px;
    height: 36px;
}

.cross-box img {
    width: 14px;
    height: auto;
}

.heart-box {
    position: absolute;
    bottom: 15px;
    right: 16px;
    display: grid;
    place-items: center;
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.artListing_page .heart-box {
    bottom: 21px;
}

.giftingList-page .heart-box {
    bottom: 10px;
}

.gallery-wall .heart-box {
    bottom: 15px;
}

.heart-box .heart-symbol {
    width: 21px;
    height: auto;
}


.heart-box.listed .heart-symbol {
    width: 21px;
    height: auto;
    filter: opacity(0.6);
}

.frame-container .art-list .row .frame-ind .heart-box img.heart-symbol,
.frame-container .art-list .row .frame-ind .cross-box img {
    height: auto;
}

.wishlist-page .frame-ind {
    box-shadow: none;
    padding-bottom: 23px;
    padding-top: 26px;
}

.wishlist-page .remove-btn {
    position: absolute;
    background-color: #F0F0F0;
    width: 28px;
    height: 28px;
    top: 20px;
    right: 20px;
    border-radius: 50%;
    display: grid;
    place-items: center;
}

.wishlist-page img.heart-symbol {
    width: 12px;
    height: 12px;
}

@media screen and (max-width: 575px) {
    .wishlist-page .row {
        column-gap: 2%;
        margin-left: 0;
        margin-right: 0;
    }

    .wishlist-page .row .frame-ind {
        flex: 0 0 48%;
    }

    .cross-box {
        width: 24px;
        height: 24px;
    }

    .cross-box img.plus-symbol {
        width: 8px;
    }

    .heart-box {
        width: 24px;
        height: 24px;
        bottom: 5px;
        right: 0.5rem;
    }

    .artListing_page .heart-box {
        bottom: 15px;
    }

    .giftingList-page .heart-box {
        bottom: 5px;
    }

    .gallery-wall .heart-box {
        bottom: 5px;
    }

    .heart-box img.heart-symbol,
    .gallery-select .frame-ind img.heart-symbol {
        width: 10px;
        height: auto;
    }

    .wishlist-add-box {
        width: 54px;
        top: 15px;
        right: 15px;
    }

    .heart-box.listed .heart-symbol {
        width: 14px;
    }
}