.partner-subcontainer{
    padding-top: 120px;
}
.partner-subcontainer1{
    padding-top: 120px;
    padding-bottom: 200px;
    width: 90%;
}
.partner-title{
    color: #1D1F22;
    padding-bottom: 20px;
    
}

.letspace{
    letter-spacing: 1px;
}

.partner-subtitle{
    font-size: 28px;
    line-height: 30px;
    padding-bottom: 60px;
}
.partner-subtitle1{
    padding-top: 20px;
    font-size: 24px;
    line-height: 30px;
    /* padding-bottom: 60px; */
}

.partner-image{
    margin-top: 20px;
    width: 95%;
    height: auto;
}

.partner-button{
    width: auto;
    padding: 0 3%;
    height: 52px;
    color: white;
    background-color: #404040;
}

@media only screen and (max-width:1300px){
    .partner-image {
        width: 95%;
        height: auto;
    }
}
@media only screen and (max-width:576px){

    .partner-subcontainermain{
        padding: 0;
    }

    .partner-subcontainer{
        padding-top: 40px;
    }

    .partner-subcontainer1{
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .partner-subtitle{
        font-size: 16px;
        line-height: 28px;
        padding-bottom: 30px;
    }

    .partner-image {
        width: 100%;
        height: auto;
    }
    .partner-subcontainer{
        padding-left: 35px;
        padding-right: 35px;
    }
    .partner-subcontainer1{
        padding-left: 50px;
        padding-right: 50px;
    }
    .partner-title{
        font-size: 28px;
        line-height: 40px;
        white-space: pre-line;
    }

    .partner-button{
        width: auto;
        padding: 0px 5%;
        height: 41px;
    }
    .partner-subtitle1{
        font-size: 20px;
        line-height: 28px;
        padding-bottom: 40px;
        padding-top: 14px;
    }
}
